import { Box, Drawer } from "@material-ui/core";
import { useState } from "react";
import AddAlert from "./AddAlert";
import SetAlert from "./SetAlert";
import AlertsHead from "./AlertsHead";

const AlertsDrawer = ({ open, onClose }) => {
  const [alertScreen, setAlert] = useState("set"); // "set" for Set Alert, "add" for Add Alert

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={1} maxWidth={"60vw"} width={"100%"}>
        <AlertsHead
          alertScreen={alertScreen}
          setAlert={setAlert}
          closeDrawer={onClose}
        />

        {alertScreen === "add" ? (
          <AddAlert setAlert={setAlert} />
        ) : (
          <SetAlert setAlert={setAlert} />
        )}
      </Box>
    </Drawer>
  );
};
export default AlertsDrawer;
