import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
  Box,
  TableHead,
} from "@material-ui/core";
import CustomButton from "../../UI/button/button";

const surveyData = [
  {
    name: "Vendor Dinancial Stability Assessment",
    status: "Active",
    date: "24th Mar 2025",
  },
  {
    name: "Legal And Regulatory Compliance Assessment",
    status: "Active",
    date: "31st June 2025",
  },
  {
    name: "Operational Capability Assessment",
    status: "Active",
    date: "31st June 2025",
  },
  {
    name: "Security And Data Protection Assessment",
    status: "Active",
    date: "15th Sep 2025",
  },
];

const DueDiligence = ({
  classes,
  setShareSurveyDrawer,
  setViewSurveyDrawer,
  data,
  setSurveyData,
  handleGetSingleSurvey,
}) => {
  return (
    <Card>
      <CardContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          style={{ marginBottom: "10px" }}
        >
          <Box>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 600 }}
            >
              Due Diligence (
              {data?.filter((survey) => survey?.isShared)?.length}/
              {data?.length})
            </Typography>
            <Typography variant="body2" gutterBottom>
              Select the required API from the options to facilitate smooth
              integration, guaranteeing effective data transfer and automated
              processes that align with the chosen evaluation procedure.
            </Typography>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last Submission</TableCell>
                <TableCell>View Survey</TableCell>
                <TableCell align="center">Share Survey</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((survey, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Switch
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.checked,
                        track: classes.track,
                      }}
                      checked
                    />
                    {survey?.name}
                  </TableCell>
                  <TableCell>
                    <Chip label={survey?.status} className={classes.chip} />
                  </TableCell>
                  <TableCell>{survey?.date || "-"}</TableCell>
                  <TableCell>
                    <CustomButton
                      color="primary"
                      onClick={() => {
                        setViewSurveyDrawer(true);
                        handleGetSingleSurvey(survey?._id);
                      }}
                    >
                      View
                    </CustomButton>
                  </TableCell>
                  <TableCell align="center">
                    <CustomButton
                      className={classes.button}
                      color="primary"
                      onClick={() => {
                        setShareSurveyDrawer(true);
                        setSurveyData(survey);
                      }}
                      disabled={survey?.isShared}
                    >
                      {survey?.isShared ? "Shared" : "Share"}
                    </CustomButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default DueDiligence;
