import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function CompilanceScore({ score }) {
  const dispatch = useDispatch();

  const storedVendorId = localStorage.getItem("vendorId");

  const nameFormat = (name) => {
    if (name === "complianceScore") {
      return "Compliance Score";
    }
  };

  const defaultData = {
    overallScore: {
      comment: "Some Comment",
      score: 0,
    },
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {defaultData && (
        <Grid md={12}>
          <BlueBackgroundCard heading={nameFormat("complianceScore")} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            {score && (
              <Chip
                label={
                  parseFloat(score) <= 33
                    ? "Low"
                    : parseFloat(score) > 33 && parseFloat(score) <= 66
                    ? "Mid"
                    : "High"
                }
                size="small"
                style={{
                  color:
                    parseFloat(score) <= 33
                      ? severity("text", "red")
                      : parseFloat(score) > 33 && parseFloat(score) <= 66
                      ? severity("text", "yellow")
                      : severity("text", "green"),
                  backgroundColor:
                    parseFloat(score) <= 33
                      ? severity("bg", "red")
                      : parseFloat(score) > 33 && parseFloat(score) <= 66
                      ? severity("bg", "yellow")
                      : severity("bg", "green"),
                }}
              />
            )}
            <Typography
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "40px" }}>{score || "-"}</span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{
              fontSize: "13px",
              padding: "5px",
              marginBottom: "10px",
            }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default CompilanceScore;
