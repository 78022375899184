import { Box, Chip, Typography } from "@material-ui/core";
import React from "react";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function RisksScore() {
  return (
    <Box>
      <BlueBackgroundCard heading={"Risks Score"} />
      <Box
        display={"flex"}
        alignItems={"center"}
        gridGap={"15px"}
        marginLeft={"20px"}
      >
        <Chip label={"Mid"} size="small" />
        <Typography
          style={{
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "40px" }}>79</span>
          <span style={{ fontSize: "20px" }}>/</span>
          <span>100</span>
        </Typography>
      </Box>
      <Typography
        style={{
          fontSize: "13px",
          padding: "5px",
          marginBottom: "10px",
        }}
        // component={Paper}
      >
        The score reflects the vendor's performance and can guide them in making
        informed decisions.
      </Typography>
    </Box>
  );
}

export default RisksScore;
