import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";

function Introduction({ classes }) {
  return (
    <Card style={{ margin: "20px 0" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>Introduction</Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>

      <CardContent>
        <Typography variant="body2">
          The purpose of this report is to provide a comprehensive evaluation of
          the vendor, PayWise, covering the entire lifecycle from the initial
          vendor intake and scoping to the eventual termination and offboarding
          process.
        </Typography>
        <br />
        <Typography variant="body2">
          This assessment encompasses key phases including Vendor
          Intake/Scoping, where the vendor's suitability and alignment with the
          bank's requirements were evaluated; Risk Assessment, to identify
          potential operational, security, and compliance risks; Due Diligence,
          ensuring the vendor meets legal, financial, and operational standards;
          Contracting, which formalized the terms of engagement; Onboarding,
          which facilitated smooth integration; Monitoring, to ensure ongoing
          compliance and performance management; and finally, Termination and
          Offboarding, detailing the controlled exit and continuity planning for
          the vendor.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Introduction;
