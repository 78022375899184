import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function OngoingAssessmentOverallScore() {
  const dispatch = useDispatch();

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    // dispatch(
    //   gql_get_vendor_materiality_scores(
    //     vendorBasicDetails?._id || storedVendorId
    //   )
    // );
  }, []);

  const nameFormat = (name) => {
    if (name === "overallScore") {
      return "Overall Score";
    } else if (name === "climateRiskScore") {
      return "Climate Risk Score";
    }
  };

  const defaultData = {
    overallScore: {
      comment: "Some Comment",
      score: 0,
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {defaultData &&
        Object?.entries(defaultData)?.map(([key, value], index) => (
          <Grid md={12}>
            {/* <BlueBackgroundCard heading={nameFormat(key)} /> */}
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Overall Score
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"15px"}
              marginLeft={"20px"}
            >
              <Chip label={"Mid"} size="small" />
              <Typography
                style={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "40px" }}>60</span>
                <span style={{ fontSize: "20px" }}>/</span>
                <span>100</span>
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "13px",
                padding: "5px",
                marginBottom: "10px",
              }}
              // component={Paper}
            >
              The score reflects the vendor's performance and can guide them in
              making informed decisions.
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
}

export default OngoingAssessmentOverallScore;
