import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Header from "../vendorIntake/Header";
import ContractingOverallScore from "./ContractingOverallScore";
import CompilanceScore from "../dueDiligence/ComplianceScore";
import ComplianceList from "../dueDiligence/ComplianceList";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_dora_compliance_results } from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import DoraDocuments from "../../vendor/DoraDocuments";

function Contracting() {
  const [docId, setDocId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");
  const dispatch = useDispatch();

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );

  const { doraComplianceResults, doraComplianceFiles } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { doraOverallScores } = doraComplianceFiles;

  const storedVendorId = localStorage.getItem("vendorId");

  const handleSelectedDoc = (id, pdfUrl, pdfName) => {
    setDocId(id);
    dispatch(gql_get_dora_compliance_results(id));
    setPdfUrl(pdfUrl);
    setPdfName(pdfName);

    console.log(id, pdfUrl, pdfName);
  };

  const customDoraResults =
    doraComplianceResults?.length > 0 ? [...doraComplianceResults] : [];

  return (
    <Box style={{ height: "63vh" }}>
      {docId === null && (
        <>
          <Box>
            <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
              Contracting
            </Typography>
          </Box>

          <Box className="scroll">
            {/* <Header />
        <br /> */}
            <ContractingOverallScore />
            <br />
            <CompilanceScore score={doraOverallScores?.overallScore} />
            <br />
            <ComplianceList
              type={"Contract"}
              handleSelectedDoc={handleSelectedDoc}
            />
          </Box>
        </>
      )}
      {docId !== null && (
        <DoraDocuments
          setDocId={setDocId}
          docId={docId}
          pdfUrl={pdfUrl}
          pdfName={pdfName}
          fileData={customDoraResults[0]?.data}
        />
      )}
    </Box>
  );
}

export default Contracting;
