import UNIVERSAL from "../../../config";
import { SET_SHOW_SURVEYS } from "../../../constants/brmConstants";
import {
  SET_ARTICLES,
  SET_COMPLETED_QUESTIONS,
  SET_CONTROVERSIES_DETAILS,
  SET_CONTROVERSIES_SCORE,
  SET_ESG_INSIGNTS,
  SET_ESG_SCORE,
  SET_FREQUENCIES,
  SET_SELF_ASSESSMENT_SCORE,
  SET_TPRM_SCORE,
  DEFAULT_VENDOR_USER_TOKEN,
  SET_CONTRO_INSIGHTS,
  SET_VENDOR_ASSESSMENT_SCORE,
  SET_DORA_COMPLIANCE_FIELS,
  SET_DORA_COMPLIANCE_RESULTS,
} from "../../../constants/esgDiagnosisConstant";
import {
  GET_CONTROVERCIES_SCORE,
  GET_DEFAULT_SURVEY,
  GET_SELF_ASSESSMENT_SCORE,
  GQL_GET_COMPLETED_QUESTIONS,
  GQL_GET_DORA_COMPLIANCE_FILES,
  GQL_GET_DORA_COMPLIANCE_RESULTS,
  GQL_GET_FREQUENCIES,
  GQL_GET_TPRM_SCORE,
  GQL_GET_VENDOR_ASSESSMENT_SCORE,
} from "../../graphql/gql_EsgDiagnosis";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";
import encrypt from "../shared/sharedActions";

const setEsgScore = (data) => {
  return { type: SET_ESG_SCORE, payload: data };
};

export const rest_get_esg_score = (isin, token) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
        year: "2022-2023",
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_esg_details`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      dispatch(setEsgScore(result?.result));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setEsgInsights = (data) => {
  return { type: SET_ESG_INSIGNTS, payload: data };
};

export const rest_get_esg_insights = (isin, token, year) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
        year: "2022-2023",
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_company_insights`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      dispatch(setEsgInsights(result?.result));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setControversiesDetails = (data) => {
  return { type: SET_CONTROVERSIES_DETAILS, payload: data };
};

export const rest_get_controversies_details = (isin, token, year) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        // "user-token":
        //   "eyJhbGciOiJIUzI1NiJ9.c2FhZEBhcHBzdG9uZS5pbg.HjRS3-CblA1TKudOd0Hw3oaofnSiwuJIZQiVqkGVHwE",
        isin,
        year: "2022-2023",
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_company_controversy_details`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      dispatch(setControversiesDetails(result?.result));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setArticles = (articles, total) => {
  return { type: SET_ARTICLES, payload: { articles, total } };
};

export const rest_get_articles = (isin, token, year, limit, skip, filter) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
        year: "2022-2023",
        limit,
        skip,
        filter,
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_articles`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      dispatch(setArticles(result?.result, result?.total));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setControInsights = (data) => {
  return { type: SET_CONTRO_INSIGHTS, payload: data };
};

export const rest_get_contro_insights = (isin) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_controversy_chart_data`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      // dispatch(
      //   setControInsights({
      //     final_dates: [
      //       {
      //         key_as_string: "2022-01-01",
      //         key: 1640995200000,
      //         doc_count: 7,
      //         month: "January",
      //       },
      //       {
      //         key_as_string: "2022-03-01",
      //         key: 1640995200000,
      //         doc_count: 15,
      //         month: "March",
      //       },
      //       {
      //         key_as_string: "2022-07-01",
      //         key: 1640995200000,
      //         doc_count: 55,
      //         month: "June",
      //       },
      //       {
      //         key_as_string: "2022-12-01",
      //         key: 1669852800000,
      //         doc_count: 2,
      //         month: "December",
      //       },
      //     ],
      //     countries: [
      //       {
      //         key: "India",
      //         doc_count: 7,
      //       },
      //       {
      //         key: "United States",
      //         doc_count: 3,
      //       },
      //       {
      //         key: "Canada",
      //         doc_count: 1,
      //       },
      //       {
      //         key: "United Arab Emirates",
      //         doc_count: 1,
      //       },
      //       {
      //         key: "United Kingdom",
      //         doc_count: 1,
      //       },
      //     ],
      //   })
      // );

      dispatch(setControInsights(result?.result));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setShowSurveys = (surveys) => {
  return { type: SET_SHOW_SURVEYS, payload: surveys };
};

export const gql_get_default_surveys = (bankId) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GET_DEFAULT_SURVEY,
        variables: { bankId },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GET_DEFAULT_SURVEY,
                variables: { bankId },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      // filter out published versions only
      const filteredSurvey = result.data?.surveys?.surveys.filter(
        (e) => e?.publishedVersion !== null
      );

      await dispatch(setShowSurveys(filteredSurvey));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setSelfAssessmentScore = (data) => {
  return { type: SET_SELF_ASSESSMENT_SCORE, payload: data };
};

export const gql_get_self_assessment_score = (getScoreInput) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GET_SELF_ASSESSMENT_SCORE,
        variables: { getScoreInput },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GET_SELF_ASSESSMENT_SCORE,
                variables: { getScoreInput },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setSelfAssessmentScore(result.data?.selfAssessmentScore));
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setControversiesScore = (data) => {
  return { type: SET_CONTROVERSIES_SCORE, payload: data };
};

export const gql_get_controversies_score = (getScoreInput) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GET_CONTROVERCIES_SCORE,
        variables: { getScoreInput },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GET_CONTROVERCIES_SCORE,
                variables: { getScoreInput },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setControversiesScore(result.data?.controversyScore));
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setFrequencies = (data) => {
  return { type: SET_FREQUENCIES, payload: data };
};

export const gql_get_frequencies = (surveyResponseFrequenciesInput) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_FREQUENCIES,
        variables: {
          surveyResponseFrequenciesInput,
        },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_FREQUENCIES,
                variables: { surveyResponseFrequenciesInput },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setFrequencies(result.data?.surveyResponseFrequencies));
    } catch (err) {
      dispatch(setFrequencies([]));
      console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setCompletedQuestions = (data) => {
  return { type: SET_COMPLETED_QUESTIONS, payload: data };
};

export const gql_get_completed_questions = (getCompletedQuestionsInput) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_COMPLETED_QUESTIONS,
        variables: {
          getCompletedQuestionsInput,
        },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_COMPLETED_QUESTIONS,
                variables: { getCompletedQuestionsInput },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setCompletedQuestions(result.data?.getCompletedQuestions));
    } catch (err) {
      console.log(err);
      dispatch(setCompletedQuestions([]));
    }

    dispatch(unset_loader());
  };
};

const setTprmScore = (data) => {
  return { type: SET_TPRM_SCORE, payload: data };
};

export const gql_get_tprm_score = (getScoreInput) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_TPRM_SCORE,
        variables: { getScoreInput },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_TPRM_SCORE,
                variables: { getScoreInput },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setTprmScore(result.data.tprmSurveyScores));
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setVendorAssessmentScore = (data) => {
  return { type: SET_VENDOR_ASSESSMENT_SCORE, payload: data };
};

export const gql_get_vendor_Assessment_score = (wazuhGroup) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_VENDOR_ASSESSMENT_SCORE,
        variables: { wazuhGroup },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_VENDOR_ASSESSMENT_SCORE,
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(
        setVendorAssessmentScore(result.data.vendorAssessmentMetrics)
      );
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setDoraComplianceFiles = (data) => {
  return { type: SET_DORA_COMPLIANCE_FIELS, payload: data };
};

export const gql_get_dora_compliance_files = (isin, type) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_DORA_COMPLIANCE_FILES,
        variables: { isin, type },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_DORA_COMPLIANCE_FILES,
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setDoraComplianceFiles(result?.data));
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};

const setDoraComplianceResults = (data) => {
  return { type: SET_DORA_COMPLIANCE_RESULTS, payload: data };
};

export const gql_get_dora_compliance_results = (fileId) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GQL_GET_DORA_COMPLIANCE_RESULTS,
        variables: { fileId },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GQL_GET_DORA_COMPLIANCE_RESULTS,
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setDoraComplianceResults(result.data.magpieResult));
    } catch (err) {
      // console.log(err);
    }

    dispatch(unset_loader());
  };
};
