import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import VendorScore from "./VendorScore";
import VendorMaterialityScores from "./VendorMaterialityScores";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_survey_list_dataform,
  gql_get_survey_response_frequencies,
  gql_get_vendor_materiality_scores,
} from "../../../../redux/actions/vendorActions/vendorAction";
import { gql_get_vendor_Assessment_score } from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import AssessmentTable from "./AssessmentTable";
import AlertsDrawer from "./AlertsDrawer";

function VendorIntake() {
  const dispatch = useDispatch();

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );
  const { bankId } = useSelector((state) => state.login);

  const storedVendorId = localStorage.getItem("vendorId");
  const storedWazuhGroup = localStorage.getItem("wazuhGroup");

  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      gql_get_vendor_materiality_scores(
        vendorBasicDetails?._id || storedVendorId
      )
    );

    // dispatch(
    //   gql_get_vendor_Assessment_score(
    //     vendorBasicDetails?.wazuhGroup || storedWazuhGroup
    //   )
    // );

    async function getSurveys() {
      await dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }

    getSurveys();
  }, []);

  useEffect(() => {
    const surveyIds = surveyListDataForm?.["vendorScopingSurveys"]?.map(
      (item) => item?._id
    );

    const obj = {
      bankId,
      vendorId: vendorBasicDetails?._id || storedVendorId,
      surveyIds,
    };

    if (surveyListDataForm?.["vendorScopingSurveys"]?.length > 0) {
      dispatch(gql_get_survey_response_frequencies(obj));
    }
  }, [surveyListDataForm]);

  return (
    <Box style={{ height: "63vh" }}>
      <Box
        sx={{
          mb: 2,
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Vendor Intake/Scoping
        </Typography>

        <Button
          variant="text"
          style={{ color: "#3374B9", textTransform: "none" }}
          onClick={toggleAlertsDrawer}
        >
          Set Alerts
        </Button>
      </Box>

      <Box className="scroll">
        <AssessmentTable />
        {/* Score */}
        <VendorScore />
        {/* <br /> */}
        <VendorMaterialityScores />
        <br />
        {/* <Header /> */}
      </Box>

      <AlertsDrawer open={alertsDrawer} onClose={toggleAlertsDrawer} />
    </Box>
  );
}

export default VendorIntake;
