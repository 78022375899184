import { Box, Typography } from "@material-ui/core";
import React from "react";
import Header from "../vendorIntake/Header";
import MonitoringOverallScore from "./MonitoringOverallScore";
import RisksScore from "./RisksScore";
import MonitoringCompanyFindings from "./MonitoringCompanyFindings";
import IncidentOverview from "./IncidentOverview";

function Monitoring() {
  return (
    <Box style={{ height: "63vh" }}>
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Monitoring
        </Typography>
      </Box>

      <Box className="scroll">
        {/* <Header />
        <br /> */}
        <MonitoringOverallScore />
        <br />
        <RisksScore />
        <br />
        <MonitoringCompanyFindings />
        <br />
        <IncidentOverview />
      </Box>
    </Box>
  );
}

export default Monitoring;
