import React from "react";
import moment from "moment";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
} from "@material-ui/core";
import tableStatusColors from "../../../../utils/getColors/tableStatusColors";

const AssessmentTable = () => {
  // Table Headers
  const tableHeaders = [
    "Assessment Name",
    "Frequency",
    "Date of Assessment",
    "Status",
    "Open Chat",
  ];

  // Temporary data for rows
  const rows = [
    {
      name: "Assessment 1",
      frequency: "Monthly",
      date: "2024-01-15T00:00:00Z",
      status: "Completed",
    },
    {
      name: "Assessment 2",
      frequency: "Quarterly",
      date: "2024-03-01T00:00:00Z",
      status: "In Progress",
    },
    {
      name: "Assessment 3",
      frequency: "Yearly",
      date: "2024-08-21T00:00:00Z",
      status: "Pending",
    },
  ];

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#EBF1F8",
              borderBottom: "1px solid #A1BFDF",
            }}
          >
            {tableHeaders.map((head) => (
              <TableCell
                style={{ fontWeight: 600, color: "#15314E" }}
                key={head}
              >
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const { bgColor, color } = tableStatusColors({
              type: "CIP",
              status: row.status,
            });

            return (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.frequency}</TableCell>
                <TableCell>{moment(row.date).format("Do MMM, YYYY")}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    style={{
                      width: "10em",
                      backgroundColor: bgColor,
                      color: color,
                      fontWeight: 400,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    style={{ color: "#3374B9", textTransform: "none" }}
                  >
                    Open Chat
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AssessmentTable;
