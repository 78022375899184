import React, { useEffect, useRef, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounce } from "lodash";
import axios from "axios";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import RemoveIcon from "@material-ui/icons/Remove";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  radioOption,
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT,
  QUESTION_TYPE_SINGLE_CHOICE,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC,
  QUESTION_TYPE_SINGLE_CHOICE_DOC,
  QUESTION_TYPE_CURRENCY,
  QUESTION_TYPE_CURRENCY_FIELD,
  QUESTION_TYPE_NUMERIC_FIELD,
  QUESTION_TYPE_TEXT_DOCUMENT,
  CUSTOM_QUESTION_TYPE_SINGLE_CHOICE,
  QUESTION_TYPE_EMAIL_FIELD,
  QUESTION_TYPE_URL_FIELD,
  QUESTION_TYPE_NUMBER_DOCUMENT,
  QUESTION_TYPE_TABLE,
  QUESTION_TYPE_DROPDOWN,
} from "./QuestionData";
import CustomButton from "../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedOption,
  submitAnswer,
} from "../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";
import RadioButton from "../../../../../../UI/radioButton/radioButton";
import { UpdateQuestionOption } from "../../../../../../../redux/actions/taskActions/TaskAction";
import { set_snack_bar } from "../../../../../../../redux/actions/snackbar/snackbar_action";
import Textfield from "../../../../../../UI/textfield/textfield";
import TrashIcon from "../../../../../../../../src/images/trashRed.png";
import { from } from "@apollo/client";
import RiskDrawer from "../NewQuestionTabComps/RightSection/riskDrawer/RiskDrawer";
// import PdfModal from "../../../../../../../utils/pdfModal/PdfModal";
// import ReactPDF from "@react-pdf/renderer";

function QuestionBody({
  questionId,
  questionType,
  answers,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  handleQuesAnswer,
  isFrom,
  event,
  variant,
  comment,
  usingQues,
  setTableHeader,
  tableHeadersApi,
  linkageAnswer,
}) {
  switch (questionType) {
    case QUESTION_TYPE_COMMENT_BOX:
      return (
        <CommentBox
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case QUESTION_TYPE_DOCUMENT_UPLOAD:
      return (
        <DocumentUpload
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case QUESTION_TYPE_MULTICHOICE:
      return (
        <MultiChoice
          questionId={questionId}
          option={answers}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          answers={answers}
          submittedAnswer={submittedAnswer}
          removeEditOption={removeEditOption}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          isFrom={isFrom}
          event={event}
          comment={comment}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
        />
      );
    case DATE_PICKER_SINGLE_DATE:
      return (
        <DatePicker
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case DATE_PICKER_RANGE_DATE:
      return (
        <DatePicker
          hasRange={true}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_SCALE:
    case SUB_QUESTION_0_10:
    case SUB_QUESTION_LIKERT:
    case SUB_QUESTION_SATISFACTION:
    case SUB_QUESTION_QUALITY:
    case SUB_QUESTION_FREQUENCY:
    case SUB_QUESTION_PERFORMANCE:
    case SUB_QUESTION_IMPORTANCE:
    case SUB_QUESTION_EMOTIONS:
      return (
        <Scale
          questionId={questionId}
          questionType={questionType}
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          variant={variant}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_LOCATION:
      return (
        <Location
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TEXT_FIELD:
      return (
        <TextFieldQue
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    // Satisfaction

    case QUESTION_TYPE_SINGLE_CHOICE:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC:
    case QUESTION_TYPE_SINGLE_CHOICE_DOC:
      return (
        <SingleChoiceWithMultiFeatures
          questionId={questionId}
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
        />
      );

    // case CUSTOM_QUESTION_TYPE_SINGLE_CHOICE:
    //   return (
    //     <CustomSingleChoice
    //       options={answers}
    //       submittedAnswer={submittedAnswer}
    //       responseAnswer={responseAnswer}
    //       isFrom={isFrom}
    //       handleQuesAnswer={handleQuesAnswer}
    //       questionType={questionType}
    //       event={event}
    //       comment={comment}
    //       checkBoxOption={checkBoxOption}
    //       setCheckBoxOption={setCheckBoxOption}
    //     />
    //   );

    case QUESTION_TYPE_CURRENCY_FIELD:
      return (
        <CurrencyField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_NUMERIC_FIELD:
      return (
        <NumberField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TEXT_DOCUMENT:
      return (
        <TextFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_EMAIL_FIELD:
      return (
        <EmailQuestion
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_URL_FIELD:
      return (
        <UrlField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_NUMBER_DOCUMENT:
      return (
        <NumberFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TABLE:
      return (
        <TableTypeQuestion
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          setTableHeader={setTableHeader}
          tableHeadersApi={tableHeadersApi}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_DROPDOWN:
      return (
        <Dropdown
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
        />
      );

    // Add more cases as needed
    default:
      return <></>;
  }
}

const TableTypeQuestion = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  setTableHeader,
  tableHeadersApi,
  linkageAnswer,
}) => {
  const [headers, setHeaders] = useState([]);
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const [row, setRow] = useState([[]]);
  const [commentBox, setCommentBox] = useState("");
  // const [modalOpen, setModalOpen] = useState(false);
  // const pdfUrl = "https://example.com/path/to/your/pdf.pdf";

  const inputRefs = useRef({});
  const checkClickRef = useRef(null);

  const columnTypes = ["Document", "Text", "Number", "Url", "Email"];

  const onTypesChange = (newTypes) => {
    setTypes(newTypes);
  };

  const onHeadersChange = (newHeaders) => {
    setHeaders(newHeaders);
  };

  const handleHeaderChange = (event, index) => {
    const newHeaders = [...headers];
    newHeaders[index] = event.target.value;
    setHeaders(newHeaders);
    onHeadersChange(newHeaders);
    setCategory((prev) => {
      const newValue = [...category];
      newValue[index] = { ...category[index], title: event.target.value };

      return newValue;
    });
  };

  const handleTypeChange = (event, index) => {
    const newTypes = [...types];
    newTypes[index] = event.target.value;
    setTypes(newTypes);
    onTypesChange(newTypes);

    let modifiedType;
    if (event.target.value === "Text") {
      modifiedType = "TF";
    } else if (event.target.value === "Document") {
      modifiedType = "DOCUMENT_UPLOAD";
    } else if (event.target.value === "Email") {
      modifiedType = "EMAIL";
    } else if (event.target.value === "Number") {
      modifiedType = "NF";
    } else if (event.target.value === "Url") {
      modifiedType = "URL";
    }

    setCategory((prev) => {
      const newValue = [...category];
      newValue[index] = { ...category[index], type: modifiedType };

      return newValue;
    });
  };

  const handleRowData = (event, cellIndex, rowIndex, type) => {
    const formattedValue = event.target.value.replace(/\D/g, "");
    const newData = [...row];

    if (type === "Document") {
      newData[rowIndex][cellIndex] = {
        option: null,
        weightage: 2,
        documentUrl: event.target.files[0],
        comment: null,
        type,
      };
    } else {
      newData[rowIndex][cellIndex] = {
        option:
          type === "Number"
            ? formattedValue
            : type === "Email"
            ? event.target.value.toLowerCase()
            : event.target.value,
        weightage: 2,
        documentUrl: null,
        comment: null,
        type,
      };
    }

    setRow(newData);
  };

  const handleFileInputClick = (rowIndex, cellIndex) => {
    const key = `${rowIndex}-${cellIndex}`;
    if (inputRefs.current[key]) {
      inputRefs.current[key].current.click();
    }
  };

  const addHeader = () => {
    const newHeaders = [...headers, `Header ${headers?.length + 1}`];
    const newTypes = [...types, "TF"];
    setHeaders(newHeaders);
    setTypes(newTypes);
    onHeadersChange(newHeaders);
    onTypesChange(newTypes);

    setCategory((prev) => {
      const newValue = [
        ...category,
        {
          type: "TF",
          title: `Header ${headers?.length + 1}`,
        },
      ];

      return newValue;
    });
  };

  const removeHeader = (index) => {
    const newHeaders = headers?.filter((_, i) => i !== index);
    const newTypes = types?.filter((_, i) => i !== index);
    setHeaders(newHeaders);
    setTypes(newTypes);
    onHeadersChange(newHeaders);
    onTypesChange(newTypes);

    setCategory((prev) => {
      const removeObj = category?.filter((_, i) => i !== index);
      return removeObj;
    });
  };

  const addRow = () => {
    if (isFrom === "taskSection") {
      const rowData = Array.from({ length: tableHeadersApi?.length }, () => {
        return {
          option: null,
          weightage: 2,
          documentUrl: null,
          comment: null,
        };
      });

      const newRow = [...row, rowData];
      setRow(newRow);
    }
  };

  const removeRow = (index) => {
    const remove = row?.filter((_, i) => i !== index);
    setRow(remove);
  };

  useEffect(() => {
    if (isFrom === "surveyCreation") {
      setTableHeader(category);
    }
  }, [category]);

  useEffect(() => {
    setHeaders((prev) => tableHeadersApi?.map((header) => header?.title));

    setTypes((prev) => tableHeadersApi?.map((header) => header?.type));

    setCategory(tableHeadersApi);

    if (isFrom === "taskSection") {
      const rowData = Array.from({ length: tableHeadersApi?.length }, () => {
        return {
          option: null,
          weightage: 2,
          documentUrl: null,
          comment: null,
        };
      });

      setRow(() => [rowData]);
    }
  }, [tableHeadersApi]);

  const formattedType = (value) => {
    if (value === "TF") {
      value = "Text";
    } else if (value === "DOCUMENT_UPLOAD") {
      value = "Document";
    } else if (value === "EMAIL") {
      value = "Email";
    } else if (value === "NF") {
      value = "Number";
    } else if (value === "URL") {
      value = "Url";
    }
    return value;
  };

  const shortenFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    }

    const headLength = Math.floor((maxLength - 3) / 2);
    const tailLength = Math.ceil((maxLength - 3) / 2);

    const head = fileName?.substring(0, headLength);
    const tail = fileName?.substring(fileName?.length - tailLength);

    return fileName === undefined ? "-" : `${head}...${tail}`;
  };

  useEffect(() => {
    if (
      isFrom === "taskSection" &&
      row?.some((ans) => ans?.some((a) => a.option !== null))
    ) {
      const withCommentOption = row?.map((opt) =>
        opt.map((op) => ({
          ...op,
          comment: commentBox,
        }))
      );

      handleQuesAnswer(withCommentOption);
    }
  }, [row, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setRow(submittedAnswer);
    }
  }, [submittedAnswer]);

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const validURL = (value) => {
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    return urlRegex.test(value);
  };

  // const handlePdfOpen = () => {
  //   setModalOpen(true);
  // };

  // const handlePdfClose = () => {
  //   setModalOpen(false);
  // };

  return (
    <Box>
      <TableContainer style={{ pointerEvents: event }}>
        <Table>
          <TableHead>
            <TableRow style={{ display: "flex" }}>
              {headers?.map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    backgroundColor:
                      isFrom !== "surveyCreation" ? "#EBF1F8" : "",
                    flex: 1,
                  }}
                >
                  {isFrom === "surveyCreation" ? (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 5 }}
                    >
                      <Box>
                        <Textfield
                          value={header}
                          onChange={(event) => handleHeaderChange(event, index)}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            marginRight: 8,
                            pointerEvents:
                              isFrom === "surveyCreation" ? "initial" : "none",
                          }}
                        />

                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            marginRight: 8,
                            minWidth: 120,
                            marginTop: 10,
                          }}
                        >
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={formattedType(types[index])}
                            onChange={(event) => handleTypeChange(event, index)}
                            label="Type"
                          >
                            {columnTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <IconButton
                        onClick={() => removeHeader(index)}
                        aria-label="delete"
                        size="small"
                        color="primary"
                        style={{}}
                      >
                        <img
                          src={TrashIcon}
                          style={{ height: "20px" }}
                          alt="upload"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isFrom !== "surveyCreation" && (
            <TableBody>
              {row?.map((rowD, rowIndex) => (
                <TableRow key={rowIndex} style={{ display: "flex" }}>
                  {headers?.map((header, cellIndex) => {
                    const key = `${rowIndex}-${cellIndex}`;
                    if (!inputRefs.current[key]) {
                      inputRefs.current[key] = React.createRef();
                    }

                    return (
                      <TableCell
                        style={{ flex: 1 }}
                        key={`${rowIndex}-${cellIndex}`}
                        onClick={() => {
                          if (formattedType(types[cellIndex]) === "Document") {
                            handleFileInputClick(rowIndex, cellIndex);
                          }
                        }}
                      >
                        {formattedType(types[cellIndex]) !== "Document" ? (
                          isFrom === "taskSection" ||
                          isFrom === "publishedSurvey" ? (
                            <Textfield
                              value={rowD[cellIndex] && rowD[cellIndex]?.option}
                              ref={checkClickRef}
                              onChange={(event) =>
                                handleRowData(
                                  event,
                                  cellIndex,
                                  rowIndex,
                                  formattedType(types[cellIndex])
                                )
                              }
                              error={
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Email" &&
                                  !validateEmail(rowD[cellIndex]?.option)) ||
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Url" &&
                                  !validURL(rowD[cellIndex]?.option))
                              }
                              helperText={
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Url" &&
                                  !validURL(rowD[cellIndex]?.option) &&
                                  "Please enter a valid URL") ||
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Email" &&
                                  !validateEmail(rowD[cellIndex]?.option) &&
                                  "Please enter a valid Email")
                              }
                              variant="outlined"
                              size="small"
                              // fullWidth
                              style={{
                                marginRight: 8,
                              }}
                            />
                          ) : (
                            <Typography
                              style={{
                                width: "100%", // Adjust the width to fill the cell
                              }}
                              variant="body2"
                            >
                              {rowD[cellIndex] &&
                              rowD[cellIndex]?.option !== null ? (
                                rowD[cellIndex]?.option
                              ) : (
                                <span>&#8212;</span>
                              )}
                            </Typography>
                          )
                        ) : (
                          <Box
                            style={{
                              width: "fit-content",
                              cursor: "pointer",
                              // height: "39px",
                            }}
                          >
                            <input
                              name="actUpload"
                              id="contained-button-activityFile"
                              type="file"
                              accept=".pdf"
                              ref={inputRefs.current[key]}
                              onChange={(event) =>
                                handleRowData(
                                  event,
                                  cellIndex,
                                  rowIndex,
                                  formattedType(types[cellIndex])
                                )
                              }
                              style={{
                                display: "none",
                                pointerEvents: event,
                              }}
                            />
                            <div
                              style={{
                                borderBottom:
                                  isFrom === "taskSection" ||
                                  isFrom === "publishedSurvey"
                                    ? "2px solid #ced4da"
                                    : "none",
                                marginTop:
                                  isFrom === "taskSection" ||
                                  isFrom === "publishedSurvey"
                                    ? 6
                                    : 0,
                                width: "100%",
                              }}
                            >
                              {rowD[cellIndex]?.documentUrl !== null ? (
                                rowD[cellIndex]?.documentUrl?.name ? (
                                  shortenFileName(
                                    rowD[cellIndex]?.documentUrl?.name,
                                    isFrom === "taskSection" ? 20 : 30
                                  )
                                ) : (
                                  "Upload file"
                                )
                              ) : isFrom === "taskSection" ||
                                isFrom === "publishedSurvey" ? (
                                "Upload file"
                              ) : (
                                <span>&#8212;</span>
                              )}
                            </div>
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                  {isFrom === "taskSection" && tableHeadersApi?.length > 0 && (
                    <IconButton
                      onClick={() => removeRow(rowIndex)}
                      aria-label="delete"
                      size="small"
                      color="primary"
                      style={{ alignSelf: "center" }}
                      // style={{ marginTop: "24px" }}
                    >
                      <img
                        src={TrashIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="upload"
                      />
                    </IconButton>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {isFrom === "surveyCreation" && (
          <CustomButton
            onClick={addHeader}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "20px" }}
          >
            Add Header
          </CustomButton>
        )}
        {isFrom === "taskSection" && (
          <CustomButton
            onClick={addRow}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "20px" }}
          >
            Add Row
          </CustomButton>
        )}
      </TableContainer>
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const NumberFieldWithUpload = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");
  const [file, setFile] = useState(null);

  const inputRef = useRef(null);

  const handleChange = (event) => {
    // Remove non-numeric characters from the input value
    const formattedValue = event.target.value.replace(/\D/g, "");

    setValue(formattedValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        document: file,
        weightage: 1,
        comment: commentBox,
      };

      handleQuesAnswer(obj);
    }
  }, [file, value]);

  useEffect(() => {
    if (linkageAnswer || (submittedAnswer && submittedAnswer?.length > 0)) {
      setValue(linkageAnswer || submittedAnswer[0]?.option);
    }
  }, [submittedAnswer, linkageAnswer]);

  return (
    <div>
      <TextField
        style={{ pointerEvents: event }}
        label={"Numeric Value"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          inputMode: "numeric", // Set input mode to allow numeric input
          pattern: "[0-9]*", // Set pattern to allow only numeric characters
        }}
      />
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {submittedAnswer ? (
          <Box
            border={1}
            borderRadius={4}
            p={1}
            borderColor={"#cececece"}
            ml={2}
          >
            {submittedAnswer[0]?.documentUrl === null ? (
              <Typography variant={"body2"}>
                No document has been uploaded
              </Typography>
            ) : (
              <a
                href={submittedAnswer[0]?.documentUrl?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer[0]?.documentUrl?.url}
              </a>
            )}
          </Box>
        ) : (
          <>
            <input
              name="actUpload"
              id="contained-button-activityFile"
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none", pointerEvents: event }}
              multiple
            />
            <div
              style={{
                borderBottom: "2px solid #ced4da",
                marginLeft: 20,
                width: "100%",
              }}
            >
              {file?.name ? file.name : "Upload file"}
            </div>
          </>
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const TextFieldWithUpload = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");

  const inputRef = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        document: file,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [file, value]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setValue(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  return (
    <div>
      <TextField
        label={"Type your text here"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        margin="normal"
        style={{ pointerEvents: event }}
      />
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {submittedAnswer ? (
          <Box
            border={1}
            borderRadius={4}
            p={1}
            borderColor={"#cececece"}
            ml={2}
          >
            {submittedAnswer[0]?.documentUrl === null ? (
              <Typography variant={"body2"}>
                No document has been uploaded
              </Typography>
            ) : (
              <a
                href={submittedAnswer[0]?.documentUrl?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer[0]?.documentUrl?.url}
              </a>
            )}
          </Box>
        ) : (
          <>
            <input
              name="actUpload"
              id="contained-button-activityFile"
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none", pointerEvents: event }}
              multiple
            />
            <div
              style={{
                borderBottom: "2px solid #ced4da",
                marginLeft: 20,
                width: "100%",
              }}
            >
              {file?.name ? file.name : "Upload file"}
            </div>
          </>
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const NumberField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleChange = (event) => {
    // Remove non-numeric characters from the input value
    const formattedValue = event.target.value.replace(/\D/g, "");

    setValue(formattedValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [value]);

  useEffect(() => {
    if (linkageAnswer || (submittedAnswer && submittedAnswer?.length > 0)) {
      setValue(linkageAnswer || submittedAnswer[0]?.option);
    }

    if (linkageAnswer !== null && isFrom === "taskSection") {
      const obj = {
        value: linkageAnswer,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [submittedAnswer, linkageAnswer]);

  return (
    <Box>
      <TextField
        style={{ pointerEvents: event }}
        label={"Numeric Value"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          inputMode: "numeric", // Set input mode to allow numeric input
          pattern: "[0-9]*", // Set pattern to allow only numeric characters
        }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const TextFieldQue = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const handleChange = (e) => {
    if (isFrom === "taskSection") {
      const newValue = e.target.value;
      setInputValue(newValue);

      const obj = {
        value: newValue,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  };

  React.useEffect(() => {
    if (!responseAnswer && linkageAnswer !== null) {
      if (submittedAnswer?.answer || submittedAnswer?.option) {
        dispatch(
          setSelectedOption(submittedAnswer?.option || submittedAnswer?.answer)
        );
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setInputValue(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  useEffect(() => {
    if (linkageAnswer !== null && isFrom === "taskSection") {
      const obj = {
        value: linkageAnswer,
        weightage: 1,
      };

      setInputValue(linkageAnswer);
      handleQuesAnswer(obj);
    }
  }, [linkageAnswer, inputValue]);

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = inputValue || submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = inputValue || selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = inputValue || submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = inputValue || responseAnswer;
  } else {
    handleValue = inputValue || "";
  }

  return (
    <Box>
      <TextField
        label="Type your text here"
        variant="outlined"
        value={handleValue}
        onChange={(e) => handleChange(e)}
        fullWidth
        disabled={linkageAnswer !== null ? true : false}
        multiline
        rows={4}
        margin="normal"
        style={{ pointerEvents: event }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const EmailQuestion = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const handleEmailChange = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
  };

  useEffect(() => {
    let obj = {};
    if (
      validateEmail(email) &&
      isFrom === "taskSection" &&
      isFrom !== "approvalSection"
    ) {
      obj = {
        value: email,
        weightage: 1,
        comment: commentBox,
      };
      setError(false);
    } else {
      setError(true);
      set_snack_bar(true, "Please enter a valid email address");
    }

    if (isFrom !== "approvalSection" && isFrom === "taskSection") {
      handleQuesAnswer(obj);
    }
  }, [email, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setEmail(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // const handleSubmit = () => {
  //   if (validateEmail(email)) {
  //     setError(false);
  //     setHelperText("");
  //     onSubmit(email); // Callback to handle submission
  //   } else {
  //     setError(true);
  //     setHelperText("Please enter a valid email address");
  //   }
  // };

  return (
    <Box style={{ pointerEvents: event }}>
      <Textfield
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        error={isFrom === "taskSection" ? error : false}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const UrlField = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [url, setUrl] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleUrlChange = (event) => {
    const newValue = event.target.value;
    setUrl(newValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: url,
        weightage: 1,
        comment: commentBox,
      };

      handleQuesAnswer(obj);
    }
  }, [url, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setUrl(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // const handleSubmit = () => {
  //   if (validateEmail(url)) {
  //     setError(false);
  //     setHelperText("");
  //     onSubmit(url); // Callback to handle submission
  //   } else {
  //     setError(true);
  //     setHelperText("Please enter a valid url address");
  //   }
  // };

  return (
    <Box style={{ pointerEvents: event }}>
      <Textfield
        label="Url"
        variant="outlined"
        fullWidth
        value={url}
        onChange={handleUrlChange}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const SingleChoiceWithMultiFeatures = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  checkBoxOption,
  setCheckBoxOption,
  usingQues,
  linkageAnswer,
  questionId,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [justification, setJustification] = useState("");
  const [file, setFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});
  const [newOption, setNewOption] = useState("");
  const [newWeightage, setNewWeightage] = useState("");

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [riskData, setRiskData] = useState({
    description: "",
    enableCompensationControl: false,
    finding: "",
    risk: "",
    severity: "",
  });

  const [optionIndex, setOptionIndex] = useState(null);
  const [compensationStatement, setCompensationStatement] = useState("");

  const inputRef = useRef(null);

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  const customOptions = [
    // { option: "Option 1", weightage: 2 },
    // { option: "Option 2", weightage: 3 },
  ];

  useEffect(() => {
    // const exceptions = [
    //   ...radioOption[SUB_QUESTION_0_10],
    //   ...radioOption[SUB_QUESTION_QUALITY],
    //   ...radioOption[SUB_QUESTION_SATISFACTION],
    //   ...radioOption[SUB_QUESTION_LIKERT],
    //   ...radioOption[SUB_QUESTION_FREQUENCY],
    //   ...radioOption[SUB_QUESTION_PERFORMANCE],
    //   ...radioOption[SUB_QUESTION_IMPORTANCE],
    //   ...radioOption[SUB_QUESTION_EMOTIONS],
    // ];

    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(customOptions);
      } else {
        setCheckBoxOption(options || customOptions);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOption = () => {
    if (newOption.trim() && newWeightage.trim()) {
      setCheckBoxOption([
        ...checkBoxOption,
        {
          option: newOption.trim(),
          weightage: parseFloat(newWeightage),
          justification: "Yes",
          documentUpload: "Yes",
        },
      ]);
      setNewOption("");
      setNewWeightage("");
    }
  };

  const handleWeightageChange = (event) => {
    setNewWeightage(event.target.value);
  };

  const handleNewOption = (event) => {
    setNewOption(event.target.value);
  };

  const handleRemoveOption = (index) => {
    setCheckBoxOption(checkBoxOption?.filter((_, i) => i !== index));
  };

  const handleOption = (opt, i) => {
    setOption(opt);
    if (isFrom !== "surveyCreation") {
    }
    setOptionIndex(i);
  };

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        justification,
        document: file,
        weightage: option?.weightage,
        riskCompensationStatement: compensationStatement,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, justification, file, compensationStatement]);

  useEffect(() => {
    return () => {
      if (isFrom === "taskSection") {
        setFile(null);
        setJustification("");
      }
    };
  }, [selectedOption]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);

      setCompensationStatement(submittedAnswer[0]?.riskCompensationStatement);

      if (submittedAnswer[0]?.justificationText) {
        setJustification(submittedAnswer[0]?.justificationText);
      }
    }
  }, [submittedAnswer]);

  const handleToggleRiskDrawer = () => {
    setToggleDrawer(false);
  };

  const handleRiskData = (e) => {
    setCheckBoxOption((prev) =>
      prev.map((item, i) => {
        if (i === optionIndex) {
          return {
            ...item,
            questionRisk: {
              ...item?.questionRisk,
              [e.target.name]: e.target.value ? e.target.value : "",
              // description:
              //   e.target.name === "description" ? e.target.value : "",
              // finding: e.target.name === "finding" ? e.target.value : "",
              // risk: e.target.name === "risk" ? e.target.value : "",
              // severity: e.target.name === "severity" ? e.target.value : "",
              enableCompensationControl: e.target.checked ? true : false,
            },
          };
        }
        return item;
      })
    );

    if (e.target.name === "enableCompensationControl") {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleMapRiskData = (i) => {
    if (isFrom === "surveyCreation") {
      setRiskData(checkBoxOption[i]?.questionRisk);
    }
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <div>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        {(checkBoxOption || options)?.length > 0 ? (
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            {(checkBoxOption || options)?.map((option, index) => (
              <>
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    key={option?.option}
                    value={option?.option}
                    control={<RadioButton color="primary" />}
                    label={option?.option}
                    style={{ pointerEvents: event }}
                    onClick={() => handleOption(option, index)}
                  />

                  {isFrom === "surveyCreation" && (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gridGap={"20px"}
                      style={{ marginRight: "10px" }}
                    >
                      <ErrorOutlineOutlinedIcon
                        onClick={() => {
                          setToggleDrawer(true);
                          setOptionIndex(index);
                          handleMapRiskData(index);
                        }}
                        style={{
                          color: !option?.questionRisk ? "#3374B9" : "#B81D13",
                          cursor: "pointer",
                        }}
                      />

                      <DeleteOutlinedIcon
                        onClick={() => handleRemoveOption(index)}
                        style={{ color: "#3374B9", cursor: "pointer" }}
                      />
                    </Box>
                  )}
                </Box>
                {((index === optionIndex &&
                  option?.questionRisk &&
                  isFrom !== "surveyCreation") ||
                  (submittedAnswer?.length > 0 &&
                    submittedAnswer[0]?.option === option?.option)) && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gridGap={7}
                    mb={1}
                    ml={3}
                  >
                    {/* <Typography>
                      Finding: <strong>{option?.questionRisk?.finding}</strong>
                    </Typography>
                    <Typography>
                      Description:{" "}
                      <strong>{option?.questionRisk?.description}</strong>
                    </Typography>
                    <Typography>
                      Severity:{" "}
                      <Chip
                        label={
                          <strong>{option?.questionRisk?.severity}</strong>
                        }
                        style={{
                          color:
                            option?.questionRisk?.severity === "HIGH"
                              ? severity("text", "red")
                              : option?.questionRisk?.severity === "MEDIUM"
                              ? severity("text", "yellow")
                              : severity("text", "green"),
                          backgroundColor:
                            option?.questionRisk?.severity === "HIGH"
                              ? severity("bg", "red")
                              : option?.questionRisk?.severity === "MEDIUM"
                              ? severity("bg", "yellow")
                              : severity("bg", "green"),
                        }}
                      ></Chip>
                      
                    </Typography> */}
                    {(option?.questionRisk?.enableCompensationControl ||
                      submittedAnswer[0]?.riskCompensationStatement) && (
                      <TextField
                        fullWidth
                        id="comment"
                        label="Compensation Statement"
                        variant="outlined"
                        multiline
                        minRows={2}
                        // placeholder="Compensation comment"
                        style={{ marginTop: "10px" }}
                        value={compensationStatement}
                        // defaultValue={usingQues?.commentBox}
                        onChange={(e) =>
                          setCompensationStatement(e.target.value)
                        }
                      />
                    )}
                  </Box>
                )}
              </>
            ))}
          </RadioGroup>
        ) : (
          <Typography style={{ marginBottom: 10 }}>Add some options</Typography>
        )}
        {isFrom === "surveyCreation" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 10,
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <TextField
              label="New Option"
              variant="outlined"
              value={newOption}
              onChange={handleNewOption}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              type="number"
              value={newWeightage}
              onChange={handleWeightageChange}
            />
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddOption}
              disabled={newOption === "" || newWeightage === ""}
            >
              Add Option
            </CustomButton>
          </div>
        )}
      </FormControl>
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.justification === "Yes") ||
        questionType === "SCT" ||
        questionType === "SCTD") && (
        <TextField
          id="justification"
          label="Justification"
          variant="outlined"
          value={justification}
          onChange={handleJustificationChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
          style={{ pointerEvents: event }}
        />
      )}
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.documentUpload === "Yes") ||
        questionType === "SCD" ||
        questionType === "SCTD") && (
        <Box
          width="100%"
          style={{
            margin: "26px 5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          <AttachFileIcon
            style={{ backgroundColor: "#ced4da", padding: 4 }}
            color="disabled"
          />
          {submittedAnswer ? (
            <Box
              border={1}
              borderRadius={4}
              p={1}
              borderColor={"#cececece"}
              ml={2}
            >
              {submittedAnswer[0]?.documentUrl === null ? (
                <Typography variant={"body2"}>
                  No document has been uploaded
                </Typography>
              ) : (
                <a
                  href={submittedAnswer[0]?.documentUrl?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#999" }}
                >
                  {submittedAnswer[0]?.documentUrl?.url}
                </a>
              )}
            </Box>
          ) : (
            <>
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none", pointerEvents: event }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {file?.name ? file.name : "Upload file"}
              </div>
            </>
          )}
        </Box>
      )}

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
      <RiskDrawer
        toggleDrawer={toggleDrawer}
        handleToggleRiskDrawer={handleToggleRiskDrawer}
        setRiskData={setRiskData}
        handleRiskData={handleRiskData}
        riskData={riskData}
      />
    </div>
  );
};

const Dropdown = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  checkBoxOption,
  setCheckBoxOption,
  usingQues,
  linkageAnswer,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});
  const [newOption, setNewOption] = useState("");
  const [newWeightage, setNewWeightage] = useState("");
  const [open, setOpen] = useState(false);

  const handleOptionChange = (event) => {
    event.preventDefault();
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  const customOptions = [
    // { option: "Option 1", weightage: 2 },
    // { option: "Option 2", weightage: 3 },
  ];

  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(customOptions);
      } else {
        setCheckBoxOption(options || customOptions);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOption = () => {
    if (newOption.trim() && newWeightage.trim()) {
      setCheckBoxOption([
        ...checkBoxOption,
        {
          option: newOption.trim(),
          weightage: parseFloat(newWeightage),
        },
      ]);
      setNewOption("");
      setNewWeightage("");
    }
  };

  const handleWeightageChange = (event) => {
    setNewWeightage(event.target.value);
  };

  const handleNewOption = (event) => {
    setNewOption(event.target.value);
  };

  const handleRemoveOption = (index) => {
    setCheckBoxOption(checkBoxOption?.filter((_, i) => i !== index));
  };

  const handleOption = (opt) => {
    setOption(opt);
    // console.log(opt);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        weightage: option?.weightage,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // console.log(checkBoxOption);

  return (
    <div>
      <FormControl
        component="fieldset"
        style={{ width: isFrom === "surveyCreation" ? "auto" : "100%" }}
      >
        {(checkBoxOption || options)?.length > 0 ? (
          <Box style={{ marginBottom: 10 }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                submittedAnswer && submittedAnswer[0]?.option
                  ? submittedAnswer[0]?.option
                  : selectedOption
              }
              onChange={handleOptionChange}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              fullWidth
              variant="outlined"
              style={{ height: "40px" }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              {(checkBoxOption || options)?.map((item, index) => (
                <MenuItem
                  value={item?.option}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  key={index}
                  onClick={() => {
                    handleOption(item);
                  }}
                >
                  <Typography>{item?.option}</Typography>

                  {isFrom === "surveyCreation" && open && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveOption(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Typography style={{ marginBottom: 10 }}>
            Add some options to the dropdown
          </Typography>
        )}

        {isFrom === "surveyCreation" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 10,
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <TextField
              label="New Option"
              variant="outlined"
              value={newOption}
              onChange={handleNewOption}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              type="number"
              value={newWeightage}
              onChange={handleWeightageChange}
            />
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddOption}
              disabled={newOption === "" || newWeightage === ""}
            >
              Add Option
            </CustomButton>
          </div>
        )}
      </FormControl>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

// const CustomSingleChoice = ({
//   answers,
//   submittedAnswer,
//   responseAnswer,
//   isFrom,
//   handleQuesAnswer,
//   questionType,
//   event,
//   comment,
//   checkBoxOption,
//   setCheckBoxOption,
// }) => {
//   const [options, setOptions] = useState([]);
//   const [newOption, setNewOption] = useState("");
//   const [newWeightage, setNewWeightage] = useState("");
//   const [selectedValue, setSelectedValue] = useState("");

//   const customOptions = [
//     { option: "Option 1", weightage: 2 },
//     { option: "Option 2", weightage: 3 },
//   ];

//   useEffect(() => {
//     const exceptions = [
//       ...radioOption[SUB_QUESTION_0_10],
//       ...radioOption[SUB_QUESTION_QUALITY],
//       ...radioOption[SUB_QUESTION_SATISFACTION],
//       ...radioOption[SUB_QUESTION_LIKERT],
//       ...radioOption[SUB_QUESTION_FREQUENCY],
//       ...radioOption[SUB_QUESTION_PERFORMANCE],
//       ...radioOption[SUB_QUESTION_IMPORTANCE],
//       ...radioOption[SUB_QUESTION_EMOTIONS],
//     ];

//     if (checkBoxOption?.length === 0 && !submittedAnswer) {
//       if (answers?.some((opt) => exceptions?.includes(opt?.option))) {
//         setCheckBoxOption(customOptions);
//       } else {
//         setCheckBoxOption(answers || customOptions);
//       }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const handleAddOption = () => {
//     if (newOption.trim() && newWeightage.trim()) {
//       setCheckBoxOption([
//         ...checkBoxOption,
//         { option: newOption.trim(), weightage: parseFloat(newWeightage) },
//       ]);
//       setNewOption("");
//       setNewWeightage("");
//     }
//   };

//   const handleOptionChange = (event) => {
//     setNewOption(event.target.value);
//   };

//   const handleWeightageChange = (event) => {
//     setNewWeightage(event.target.value);
//   };

//   const handleRadioChange = (event) => {
//     setSelectedValue(event.target.value);
//   };
//   const handleRemoveOption = (index) => {
//     setCheckBoxOption(checkBoxOption.filter((_, i) => i !== index));
//   };

//   return (
//     <FormControl component="fieldset">
//       <RadioGroup value={selectedValue} onChange={handleRadioChange}>
//         {checkBoxOption?.map((optionObj, index) => (
//           <Box style={{ display: "flex" }}>
//             <FormControlLabel
//               key={index}
//               value={optionObj.option}
//               control={<RadioButton />}
//               label={`${optionObj.option} (Weightage: ${optionObj.weightage})`}
//             />
//             <IconButton
//               aria-label="delete"
//               onClick={() => handleRemoveOption(index)}
//             >
//               <RemoveIcon />
//             </IconButton>
//           </Box>
//         ))}
//       </RadioGroup>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           gap: 10,
//           alignItems: "center",
//           marginTop: 5,
//         }}
//       >
//         <TextField
//           label="New Option"
//           variant="outlined"
//           value={newOption}
//           onChange={handleOptionChange}
//         />
//         <TextField
//           label="Weightage"
//           variant="outlined"
//           type="number"
//           value={newWeightage}
//           onChange={handleWeightageChange}
//         />
//         <CustomButton
//           variant="contained"
//           color="primary"
//           onClick={handleAddOption}
//         >
//           Add Option
//         </CustomButton>
//       </div>
//     </FormControl>
//   );
// };

// const CustomSingleChoice = ({
//   options,
//   submittedAnswer,
//   responseAnswer,
//   isFrom,
//   handleQuesAnswer,
//   questionType,
//   event,
//   comment,
//   checkBoxOption,
//   setCheckBoxOption,
// }) => {
//   const [selectedOption, setSelectedOption] = useState("");
//   const [commentBox, setCommentBox] = useState("");
//   const [option, setOption] = useState({});

//   const customOptions = [
//     { option: "option 1", weightage: 1 },
//     { option: "option 2", weightage: 2 },
//   ];

//   useEffect(() => {
//     const exceptions = [
//       ...radioOption[SUB_QUESTION_0_10],
//       ...radioOption[SUB_QUESTION_QUALITY],
//       ...radioOption[SUB_QUESTION_SATISFACTION],
//       ...radioOption[SUB_QUESTION_LIKERT],
//       ...radioOption[SUB_QUESTION_FREQUENCY],
//       ...radioOption[SUB_QUESTION_PERFORMANCE],
//       ...radioOption[SUB_QUESTION_IMPORTANCE],
//       ...radioOption[SUB_QUESTION_EMOTIONS],
//     ];

//     if (checkBoxOption?.length === 0 && !submittedAnswer) {
//       if (option.some((opt) => exceptions?.includes(opt?.option))) {
//         setCheckBoxOption(customOptions);
//       } else {
//         setCheckBoxOption(option || customOptions);
//       }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const handleOptionChange = (event) => {
//     const optionValue = event.target.value;
//     setSelectedOption(optionValue);
//     // const selectedOptionData = options.find(
//     //   (option) => option.option === optionValue
//     // );
//     // if (selectedOptionData && selectedOptionData.justification !== "Yes") {
//     //   setJustification("");
//     // }
//   };

//   const handleOption = (opt) => {
//     setOption(opt);
//   };

//   useEffect(() => {
//     if (isFrom === "taskSection") {
//       const obj = {
//         value: selectedOption,
//         weightage: option?.weightage,
//       };

//       handleQuesAnswer(obj);
//     }
//   }, [selectedOption]);

//   useEffect(() => {
//     if (submittedAnswer && submittedAnswer?.length > 0) {
//       setSelectedOption(submittedAnswer[0]?.option);
//     }
//   }, [submittedAnswer]);

//   return (
//     <div>
//       <FormControl component="fieldset">
//         <RadioGroup value={selectedOption} onChange={handleOptionChange}>
//           {options?.map((option) => (
//             <FormControlLabel
//               key={option.option}
//               value={option.option}
//               control={<RadioButton color="primary" />}
//               label={option.option}
//               style={{ pointerEvents: event }}
//               onClick={() => handleOption(option)}
//             />
//           ))}
//         </RadioGroup>
//       </FormControl>

//       {comment && (
//         <Box style={{ pointerEvents: event, marginTop: "10px" }}>
//           <TextField
//             fullWidth
//             id="comment"
//             label="Contributor's comment"
//             variant="outlined"
//             multiline
//             rows={2}
//             placeholder="Contributor's comment"
//             style={{ marginTop: "10px" }}
//             value={commentBox}
//             // defaultValue={}
//             onChange={(e) => setCommentBox(e.target.value)}
//           />
//         </Box>
//       )}
//     </div>
//   );
// };

const CurrencyField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleChange = (event) => {
    // Remove non-numeric characters and format the value as currency
    const formattedValue = event.target.value
      .replace(/[^\d.]/g, "") // Remove non-numeric characters except '.'
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2") // Ensure only one '.' is present
      .replace(/^(\d*)(\.\d{0,2}).*/, "$1$2"); // Allow only up to two decimal places

    setValue(formattedValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [value]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setValue(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  return (
    <Box>
      <TextField
        style={{ pointerEvents: event }}
        label={"Currency"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            inputMode: "numeric", // Set input mode to allow numeric input
            pattern: "[0-9]*", // Set pattern to allow only numeric characters
          },
        }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const CommentBox = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  // const handleChange = (e, type) => {
  //   // dispatch(setSelectedOption(e.target.value));
  //   // dispatch(UpdateQuestionOption(questionId, e.target.value, activeTabId));
  //   // setValue(parseInt(event?.weightage));
  //   if (isFrom === "taskSection") {
  //     const newValue = e.target.value;

  //     if (type === "comment") {
  //       setInputValue(newValue);
  //     } else if (type === "contriComment") {
  //       setCommentBox(newValue);
  //     }

  //     const obj = {
  //       value: inputValue,
  //       comment: commentBox,
  //     };
  //     handleQuesAnswer(obj);
  //   }
  // };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: inputValue,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [inputValue, commentBox]);

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer[0]));
        setCommentBox(submittedAnswer[0]?.comment);
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleValue = "";

  // if (isFrom === "taskSection") {
  //   handleValue = inputValue || submittedAnswer[0]?.option || "";
  // } else if (selectedOption?.option !== "") {
  //   handleValue = inputValue || selectedOption;
  // } else if (submittedAnswer) {
  //   handleValue = submittedAnswer[0]?.option;
  // } else {
  //   handleValue = inputValue || "";
  // }

  useEffect(() => {
    if (submittedAnswer) {
      setInputValue(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, []);

  return (
    <Box>
      <TextField
        style={{ width: "100%", pointerEvents: event }}
        aria-label="minimum height"
        rows={4}
        placeholder="Comment"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        variant="outlined"
        multiline
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={submittedAnswer && submittedAnswer[0]?.comment}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const DocumentUpload = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    // Perform upload logic here
    if (selectedFile) {
      const obj = {
        document: selectedFile,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [selectedFile, commentBox]);

  useEffect(() => {
    // Perform upload logic here
    if (submittedAnswer) {
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  return (
    <Box>
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {
          // (isFrom === "approvalSection" || isFrom === "completedSection") &&
          submittedAnswer ? (
            <Box
              border={1}
              borderRadius={4}
              p={1}
              borderColor={"#cececece"}
              ml={2}
            >
              <a
                href={submittedAnswer[0]?.documentUrl?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer[0]?.documentUrl?.url}
              </a>
            </Box>
          ) : (
            <>
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none", pointerEvents: event }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {selectedFile?.name ? selectedFile.name : "Upload file"}
              </div>
            </>
          )
        }
      </Box>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const MultiChoice = ({
  questionId,
  option,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  removeEditOption,
  handleQuesAnswer,
  questionType,
  isFrom,
  event,
  comment,
  usingQues,
  linkageAnswer,
}) => {
  // const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  // const checkBoxOptionArray = [
  //   { option: "option 1", checked: false },
  //   { option: "option 2", checked: false },
  // ];

  const checkBoxOptionArray = [
    { option: "option 1", weightage: 1 },
    { option: "option 2", weightage: 2 },
  ];

  // use in add / update
  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(checkBoxOptionArray);
      } else {
        setCheckBoxOption(option || checkBoxOptionArray);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editedText, setEditedText] = React.useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  const [commentBox, setCommentBox] = useState("");

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [riskData, setRiskData] = useState({
    description: "",
    enableCompensationControl: false,
    finding: "",
    risk: "",
    severity: "",
  });

  const [optionIndex, setOptionIndex] = useState(null);
  const [compensationStatement, setCompensationStatement] = useState("");

  let handleOption = checkBoxOption || option;

  useEffect(() => {
    if (isFrom === "taskSection") {
      // const obj = {
      //   value: handleOption,
      // };

      const withCommentOption = optionsArray?.map((opt) => ({
        ...opt,
        comment: commentBox,
      }));

      handleQuesAnswer(withCommentOption);
    }
  }, [optionsArray, commentBox]);

  const handleSubAns = () => {
    return submittedAnswer?.map((ans) => {
      return ans?.option;
    });
  };

  useEffect(() => {
    setOptionsArray(option);
  }, [option]);

  useEffect(() => {
    if (submittedAnswer) setCommentBox(submittedAnswer[0]?.comment);
  }, [submittedAnswer]);

  const handleChange = (event, optionIndex) => {
    if (isFrom !== "taskSection") {
      setCheckBoxOption((preValue) =>
        preValue.map((option, index) =>
          index === optionIndex
            ? { ...option, checked: event.target.checked }
            : option
        )
      );
    }

    if (event.target.checked) {
      setOptionIndex(optionIndex);
    } else {
      setOptionIndex(null);
    }

    setOptionsArray((prev) => {
      handleOption = prev?.map((options, index) => {
        const newOptions =
          index === optionIndex
            ? { ...options, checked: event.target.checked }
            : options;
        return newOptions;
      });

      return handleOption;
    });
  };

  const handleAddOption = () => {
    setCheckBoxOption((preValue) => [
      ...preValue,
      {
        option: `option ${preValue.length + 1}`,
        // checked: false,
        weightage: preValue.length + 1,
      },
    ]);
  };

  const handleDoubleClick = (index, currentLabel) => {
    setEditingIndex(index);
    setEditedText(currentLabel);
  };

  const handleLabelBlur = (index) => {
    setEditingIndex(null);

    const updatedOptions = checkBoxOption.map((option, i) => {
      if (i === index) {
        return { ...option, option: editedText };
      }
      return option;
    });

    setCheckBoxOption(updatedOptions);
  };

  const handleLabelChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleMapRiskData = (i) => {
    if (isFrom === "surveyCreation") {
      setRiskData(checkBoxOption[i]?.questionRisk);
    }
  };

  const handleToggleRiskDrawer = () => {
    setToggleDrawer(false);
  };

  const handleRiskData = (e) => {
    setCheckBoxOption((prev) =>
      prev?.map((item, i) => {
        if (i === optionIndex) {
          return {
            ...item,
            questionRisk: {
              ...item?.questionRisk,
              [e.target.name]: e.target.value ? e.target.value : "",
              // description:
              //   e.target.name === "description" ? e.target.value : "",
              // finding: e.target.name === "finding" ? e.target.value : "",
              // risk: e.target.name === "risk" ? e.target.value : "",
              // severity: e.target.name === "severity" ? e.target.value : "",
              enableCompensationControl: e.target.checked ? true : false,
            },
          };
        }
        return item;
      })
    );

    if (e.target.name === "enableCompensationControl") {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <FormControl component="fieldset">
          <FormGroup>
            {handleOption &&
              handleOption.map((option, index) => (
                <div key={index}>
                  {editingIndex === index ? (
                    <TextField
                      type="text"
                      value={editedText}
                      onChange={handleLabelChange}
                      onBlur={() => handleLabelBlur(index)}
                      autoFocus
                      style={{ pointerEvents: event }}
                    />
                  ) : (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // width: "60vw",
                          pointerEvents: event,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox
                                disabled={isFrom !== "taskSection"}
                                size="small"
                                style={{
                                  color: "#3374B9",
                                  pointerEvents: event,
                                }}
                                checked={
                                  submittedAnswer
                                    ? handleSubAns()?.includes(option.option)
                                    : option?.checked
                                }
                                onChange={(event) => handleChange(event, index)}
                                name={option.option}
                              />
                            </>
                          }
                          label={
                            <div style={{ wordBreak: "break-all" }}>
                              {option.option}
                            </div>
                          }
                          onDoubleClick={() =>
                            !submittedAnswer &&
                            !removeEditOption &&
                            handleDoubleClick(index, option.option)
                          }
                        />

                        {/* {!submittedAnswer && !removeEditOption && (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gridGap={"20px"}
                            style={{ marginRight: "10px" }}
                          >
                            <ErrorOutlineOutlinedIcon
                              onClick={() => {
                                setToggleDrawer(true);
                                setOptionIndex(index);
                                handleMapRiskData(index);
                              }}
                              style={{
                                color: !option?.questionRisk
                                  ? "#3374B9"
                                  : "#B81D13",
                                cursor: "pointer",
                              }}
                            />

                            <DeleteOutlinedIcon
                              onClick={() =>
                                setCheckBoxOption((preValue) =>
                                  preValue.filter((option, i) => i !== index)
                                )
                              }
                              style={{ color: "#3374B9", cursor: "pointer" }}
                            />
                          </Box>
                        )} */}
                      </Box>
                      {/* {((index === optionIndex &&
                        option?.questionRisk?.enableCompensationControl &&
                        isFrom !== "surveyCreation") ||
                        (submittedAnswer?.length > 0 &&
                          submittedAnswer[0]?.option === option?.option)) && (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gridGap={7}
                          mb={1}
                          ml={3}
                        >
                          {(option?.questionRisk?.enableCompensationControl ||
                            submittedAnswer[0]?.riskCompensationStatement) && (
                            <TextField
                              fullWidth
                              id="comment"
                              label="Compensation Statement"
                              variant="outlined"
                              multiline
                              minRows={2}
                              // placeholder="Compensation comment"
                              style={{ marginTop: "10px" }}
                              value={compensationStatement}
                              // defaultValue={usingQues?.commentBox}
                              onChange={(e) =>
                                setCompensationStatement(e.target.value)
                              }
                            />
                          )}
                        </Box>
                      )} */}
                    </>
                  )}
                </div>
              ))}
          </FormGroup>
          {!submittedAnswer && !removeEditOption && (
            <FormHelperText>Double Click to Edit Option</FormHelperText>
          )}
        </FormControl>
        {!submittedAnswer && !removeEditOption && (
          <CustomButton
            style={{ width: "15%" }}
            variant="outlined"
            color="primary"
            onClick={() => handleAddOption()}
          >
            add option
          </CustomButton>
        )}
      </Box>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={submittedAnswer}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}

      <RiskDrawer
        toggleDrawer={toggleDrawer}
        handleToggleRiskDrawer={handleToggleRiskDrawer}
        setRiskData={setRiskData}
        handleRiskData={handleRiskData}
        riskData={riskData}
      />
    </Box>
  );
};

const Scale = ({
  questionId,
  questionType,
  options,
  submittedAnswer,
  responseAnswer,
  handleQuesAnswer,
  isFrom,
  event,
  variant,
  comment,
  linkageAnswer,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});

  const handleOption = (opt) => {
    setOption(opt);
  };

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        comment: commentBox,
        weightage: option?.weightage,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  return (
    <div>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          {options?.map((option) => (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FormControlLabel
                key={option.option}
                value={option.option}
                control={<RadioButton color="primary" />}
                // control={
                //   <RadioButton color="primary" style={{ padding: "5px" }} />
                // }
                // label={option.option}
                onClick={() => handleOption(option)}
                label={
                  <Typography variant={variant}>{option.option}</Typography>
                }
                style={{ pointerEvents: event }}
              />
              {/* <span style={{ marginRight: "10px" }}>
                <ErrorOutlineOutlinedIcon style={{ color: "#3374B9" }} />
              </span> */}
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const DatePicker = ({
  hasRange = false,
  submittedAnswer,
  responseAnswer,
  handleQuesAnswer,
  isFrom,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);
  const [commentBox, setCommentBox] = useState("");

  const handleStartDateChange = (key, date) => {
    dispatch(
      setSelectedOption(
        selectedOption !== null
          ? { ...selectedOption, [key]: date }
          : { [key]: date }
      )
    );
  };

  function formatDate(dateString) {
    return dateString?.split("T")[0];
  }

  React.useEffect(() => {
    if (submittedAnswer) {
      dispatch(setSelectedOption(submittedAnswer[0]));
      setCommentBox(submittedAnswer[0]?.comment);
    } else {
      dispatch(setSelectedOption(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOption && isFrom === "taskSection") {
      const obj = {
        startDate: selectedOption?.startDate,
        endDate: selectedOption?.endDate,
        comment: commentBox,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, commentBox]);

  useEffect(() => {
    if (linkageAnswer !== null && isFrom === "taskSection") {
      const obj = {
        startDate: linkageAnswer,
        comment: commentBox,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [linkageAnswer]);

  return (
    <Box>
      <form
        noValidate
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <TextField
          id="start-date"
          label={hasRange ? "Start Date" : "Select Date"}
          type="date"
          //   className={classes.textField}
          disabled={linkageAnswer !== null ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          value={
            submittedAnswer
              ? formatDate(selectedOption?.startDate)
              : selectedOption?.startDate
          }
          onChange={(e) => handleStartDateChange(e.target.name, e.target.value)}
          variant="outlined"
          color="primary"
          name="startDate"
          style={{ pointerEvents: event }}
        />
        {hasRange && (
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            disabled={linkageAnswer !== null ? true : false}
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={
              submittedAnswer
                ? formatDate(selectedOption?.endDate)
                : selectedOption?.endDate
            }
            onChange={(e) =>
              handleStartDateChange(e.target.name, e.target.value)
            }
            variant="outlined"
            color="primary"
            name="endDate"
            style={{ pointerEvents: event }}
          />
        )}
      </form>
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const Location = ({
  option,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const fetchLocations = debounce(async (query) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&type=locality&apiKey=6c147cbfca9e4f768c57f8fc6a1e7642`
      );
      setOptions(response?.data?.features);
    } catch (error) {
      set_snack_bar(true, "Could not find the location.");
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (submittedAnswer?.length > 0) {
      setInputValue(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    fetchLocations(value);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: inputValue,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [inputValue, commentBox]);

  const formattedLocation = (location) => {
    return `${location?.city}, ${location?.state}, ${location?.country}`;
  };

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = inputValue || submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = inputValue || selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = inputValue || submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = inputValue || responseAnswer;
  } else {
    handleValue = inputValue || "";
  }

  return (
    <Box>
      <Autocomplete
        style={{ pointerEvents: event }}
        options={options}
        getOptionLabel={(option) => option?.properties?.formatted}
        inputValue={handleValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={submittedAnswer ? "" : "Search Location"}
            variant={"outlined"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {submittedAnswer ? null : params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            rows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default QuestionBody;
