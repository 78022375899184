import React from "react";
import { Drawer, Button, Box, makeStyles, Typography } from "@material-ui/core";
import CustomButton from "../button/button";

const useStyles = makeStyles({
  drawerPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    padding: "10px",
  },
  buttonContainer: {
    padding: "10px",
    // borderTop: "1px solid #ddd",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  heading: {
    paddingBottom: "12px",
    fontWeight: 600,
  },
});

const DynamicDrawer = ({
  open,
  setOpen,
  children,
  button1Label,
  button2Label,
  onButton2Click,
  heading = "Heading here",
  dynamicWidth = 600,
}) => {
  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.drawerPaper }}
        elevation={3}
        PaperProps={{
          style: { width: dynamicWidth }, // Replace 'dynamicWidth' with your variable
        }}
      >
        <Typography className={classes.heading}>{heading}</Typography>
        <div role="presentation">{children}</div>

        {/* Buttons at the bottom */}
        <Box className={classes.buttonContainer}>
          <CustomButton
            color="primary"
            onClick={() => setOpen(false)}
            style={{ marginRight: "8px" }}
            size="small"
          >
            {button1Label}
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            size="small"
            onClick={onButton2Click}
          >
            {button2Label}
          </CustomButton>
        </Box>
      </Drawer>
    </>
  );
};

export default DynamicDrawer;
