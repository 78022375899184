import { Box, Typography } from "@material-ui/core";
import Header from "../vendorIntake/Header";
import TerminationOverallScore from "./TerminationOverallScore";
import TerminationExitPlan from "./TerminationExitPlan";
import TerminationAlternateVendorOptions from "./TerminationAlternateVendorOptions";

function TerminationAndOffboarding() {
  return (
    <Box style={{ height: "63vh" }}>
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Termination And Offboarding
        </Typography>
      </Box>

      <Box className="scroll">
        {/* <Header />
        <br /> */}
        <TerminationOverallScore />
        <br />
        <TerminationExitPlan />
        <br />
        <TerminationAlternateVendorOptions />
      </Box>
    </Box>
  );
}

export default TerminationAndOffboarding;
