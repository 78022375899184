import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";

const vendorAssessment = [
  {
    category: "Vendor Intake/Scoping",
    description:
      "PayWise was identified as a key vendor due to its strong presence in payment processing and merchant acquiring. Initial assessments indicated that the vendor's services align with AcquiBank's strategic goals.",
    score: {
      name: "Overall Score",
      value: "85%",
    },
  },
  {
    category: "Risk Assessment",
    description:
      "During the risk assessment phase, several risks were identified, including data privacy concerns and potential non-compliance with international regulations. Mitigation strategies, such as regular audits and enhanced security protocols, were proposed.",
    score: {
      name: "Overall Risk Score",
      value: "70%",
      riskLevel: "Moderate Risk",
    },
  },
  {
    category: "Due Diligence",
    description:
      "Financial and legal assessments were conducted, showing that PayWise had a stable financial standing and adhered to necessary regulatory requirements (GDPR, DORA). Security protocols were adequate but required further improvement in incident response times.",
    score: {
      name: "Due Diligence Score",
      value: "80%",
    },
  },
  {
    category: "Contracting",
    description:
      "The contractual agreements with PayWise were favorable, with clear definitions of service levels and risk allocation. The Service Level Agreements (SLAs) aligned with the bank’s requirements, ensuring performance guarantees.",
    score: {
      name: "Contracting Score",
      value: "88%",
    },
  },
  {
    category: "Onboarding",
    description:
      "PayWise's onboarding was smooth, with minimal disruptions in data integration and service deployment. Adequate training was provided to ensure proper usage of their systems.",
    score: {
      name: "Onboarding Score",
      value: "90%",
    },
  },
  {
    category: "Monitoring",
    description:
      "Regular monitoring was conducted, tracking performance metrics such as uptime, transaction speed, and customer service efficiency. Some minor compliance issues were flagged but were quickly remediated.",
    score: {
      name: "Monitoring Score",
      value: "85%",
    },
  },
  {
    category: "Termination and Offboarding",
    description:
      "Due to performance issues identified during the monitoring phase, the decision to terminate the contract with PayWise was made. The offboarding process was planned and initiated smoothly, with all critical data securely transferred to alternative systems.",
    score: {
      name: "Termination Score",
      value: "78%",
    },
  },
];

function AssessmentFindings({ classes }) {
  return (
    <Card style={{ marginBottom: "20px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>Assessment Findings</Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />{" "}
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"space-between"}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            The termination assessment for SwiftPay Solutions revealed the
            following key findings
          </Typography>
          {vendorAssessment?.map((item, i) => (
            <>
              <Typography variant="body2" key={i}>
                <strong>{item?.category}: </strong>
                {item?.description} <br />
                <span>
                  {item?.score?.name}: {item?.score?.value}
                </span>
              </Typography>
            </>
          ))}
        </CardContent>
      </Box>
    </Card>
  );
}

export default AssessmentFindings;
