import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";
import { useSelector } from "react-redux";

const scores = {
  // Group1
  company_profile: { score: 0.4 },
  information_security: { score: 4.67 },
  data_privacy: { score: 4 },
  "third_party/subcontractors": { score: 4 },
  business_and_ethical_conduct: { score: 9.2 },

  // Group2
  environment: { score: 8.33 },
  labour_standards: { score: 10 },
  human_rights: { score: 7.33 },
  compliance_compliance: { score: 8.5 },
  risk_management: { score: 10 },

  // Group3
  organizational_security: { score: 10 },
  physical_and_environmental_security: { score: 9.5 },
  asset_management: { score: 10 },
  access_control: { score: 10 },
  vulnerability_and_threat_management: { score: 9.43 },
  business_continuity_management: { score: 9.06 },
  cloud_specific_requirements: { score: 7.33 },
};

const TprmSubScoreTable = ({ calculateScores, defaultScores }) => {
  const { tprmScore } = useSelector((state) => state.esgDiagnosisReducer);

  const { totals } = calculateScores();

  const formatKey = (key) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const groups = {
    group1: {
      company_profile: scores["company_profile"],
      information_security: scores["information_security"],
      data_privacy: scores["data_privacy"],
      "third_party/subcontractors": scores["third_party/subcontractors"],
      business_and_ethical_conduct: scores["business_and_ethical_conduct"],
      environment: scores["environment"],
    },
    group2: {
      labour_standards: scores["labour_standards"],
      human_rights: scores["human_rights"],
      compliance_compliance: scores["compliance_compliance"],
      risk_management: scores["risk_management"],
      organizational_security: scores["organizational_security"],
      physical_and_environmental_security:
        scores["physical_and_environmental_security"],
    },
    group3: {
      asset_management: scores["asset_management"],
      access_control: scores["access_control"],
      vulnerability_and_threat_management:
        scores["vulnerability_and_threat_management"],
      business_continuity_management: scores["business_continuity_management"],
      cloud_specific_requirements: scores["cloud_specific_requirements"],
    },
  };

  console.log(tprmScore);

  return (
    <Grid container style={{ width: "100%" }}>
      {Object?.entries(
        tprmScore?.["Human and Environmental Resilience"]?.company_profile?.[
          "Company Profile"
        ]
          ? tprmScore
          : defaultScores
      )?.map(([key, value], index) => (
        <Grid md={4}>
          <BlueBackgroundCard heading={key} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            {/* <Chip label={"Mid"} size="small" /> */}
            <Typography
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "40px" }}>
                {parseFloat(totals[key]) || "-"}
              </span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{
              fontSize: "13px",
              padding: "5px",
              marginBottom: "10px",
            }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography>
          <Grid justifyContent="space-around">
            <Grid item md={5}>
              {/* <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  /> */}
            </Grid>
            <Grid item md={5}>
              <div
                key={index}
                style={{
                  width: "95%",
                  borderRadius: 8,
                  backgroundColor: "#FBFBFB",
                  marginTop: "20px",
                }}
              >
                {/* <TopicHeader
                    topic={formatKey(key)}
                    score={value?.score}
                    noIcon
                  /> */}
                {value &&
                  Object.entries(value)?.map(([section, scores], i) => {
                    const [label, score] = Object.entries(scores)[0];
                    return (
                      <TopicData
                        key={i}
                        topic={formatKey(label)}
                        score={parseFloat(score) || "-"}
                        marginTop={"10px"}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default TprmSubScoreTable;
