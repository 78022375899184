import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";

function ContractingOverallScore() {
  const dispatch = useDispatch();

  const { vendorBasicDetails, vendorMaterialityScores } = useSelector(
    (state) => state.brmData
  );

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    // dispatch(
    //   gql_get_vendor_materiality_scores(
    //     vendorBasicDetails?._id || storedVendorId
    //   )
    // );
  }, []);

  const defaultData = {
    overall: {
      comment: "Some Comment",
      score: 0,
    },
  };

  const scoresDefaultData = {
    overall: {
      "TPSP Name": "PayWise",
      "Contract Reference Number": "CN123456",
      "Service Provided": "Cloud Hosting",
      "Contract Start Date": "01-Jan-2023",
      "Contract End Date": "31-Dec-2025",
      "Service Level Agreements": "99.9% Uptime",
      "Performance Metrics": "Quarterly Performance",
      "Annual Audits": "Proceed",
      "Termination Clauses": "30-Day Notice Period",
      "Active Contract Document": "PayWise Contract Document Copy.pdf",
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {defaultData && (
        <Grid md={12}>
          {/* <BlueBackgroundCard heading={"Overall Score"} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            <Chip label={"Mid"} size="small" />
            <Typography
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "40px" }}>60</span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{
              fontSize: "13px",
              padding: "5px",
              marginBottom: "10px",
            }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography> */}
          <Grid justifyContent="space-around">
            <Grid item md={5}>
              <div
                style={{
                  width: "95%",
                  borderRadius: 8,
                  backgroundColor: "#FBFBFB",
                  marginTop: "20px",
                }}
              >
                <TopicHeader
                  topic={"Overall Score"}
                  // score={value?.score}
                  noIcon
                />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gridGap={10}
                >
                  <Box flex={1}>
                    {scoresDefaultData &&
                      Object.entries(scoresDefaultData["overall"])
                        .slice(0, 5)
                        ?.map(([section, score], i) => (
                          <TopicData
                            key={i}
                            topic={section}
                            // score={score}
                            textValue={score}
                            marginTop={"10px"}
                          />
                        ))}
                  </Box>
                  <Box flex={1}>
                    {scoresDefaultData &&
                      Object.entries(scoresDefaultData["overall"])
                        .slice(5)
                        ?.map(([section, score], i) => (
                          <TopicData
                            key={i}
                            topic={section}
                            // score={score}
                            textValue={score}
                            marginTop={"10px"}
                          />
                        ))}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ContractingOverallScore;
