import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "./Header";
import OverallScore from "./OverallScore";
import CompanyFindings from "./CompanyFindings";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_default_surveys,
  gql_get_tprm_score,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_SHOW_SURVEYS } from "../../../../constants/brmConstants";
import TprmSubScoreTable from "./TprmSubScoreTable";
import {
  gql_get_risk_register,
  gql_get_survey_list_dataform,
} from "../../../../redux/actions/vendorActions/vendorAction";

const defaultScores = {
  "Human and Environmental Resilience": {
    company_profile: {
      "Company Profile": 0,
    },
    information_security: {
      "Information Security": 0,
    },
    data_privacy: {
      "Data Privacy": 0,
    },
    "third_party/subcontractors": {
      "Third Party/Subcontractors": 0,
    },
    business_and_ethical_conduct: {
      "Business and Ethical Conduct": 0,
    },
    environment: {
      Environment: 0,
    },
  },
  "Digital Integrity and Compliance": {
    labour_standards: {
      "Labour Standards": 0,
    },
    human_rights: {
      "Human Rights": 0,
    },
    compliance_compliance: {
      "Compliance Compliance": 0,
    },
    risk_management: {
      "Risk Management": 0,
    },
    organizational_security: {
      "Organizational Security": 0,
    },
    physical_and_environmental_security: {
      "Physical and Environmental Security": 0,
    },
  },
  "Operational Resilience & Security": {
    asset_management: {
      "Asset Management": 0,
    },
    access_control: {
      "Access Control": 0,
    },
    vulnerability_and_threat_management: {
      "Vulnerability and Threat Management": 0,
    },
    business_continuity_management: {
      "Business Continuity Management": 0,
    },
    cloud_specific_requirements: {
      "Cloud Specific Requirements": 0,
    },
  },
};

function RiskAssessment() {
  const dispatch = useDispatch();
  const { bankId } = useSelector((state) => state?.login);
  const storedBankId = localStorage.getItem("bank_id");
  const storedVendorId = localStorage.getItem("vendorId");

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );
  const { tprmScore } = useSelector((state) => state.esgDiagnosisReducer);

  useEffect(() => {
    if (surveyListDataForm?.["vendorScopingSurveys"]?.length === 0) {
      dispatch(gql_get_survey_list_dataform(vendorBasicDetails?._id));
    }

    const surveyId = surveyListDataForm?.["riskAssessmentSurveys"].find(
      (item) => (item?.name).toLowerCase() === "dora tprm"
    );

    const getScoreInput = {
      vendorId: vendorBasicDetails?._id || storedVendorId,
      surveyId: surveyId?._id,
    };
    dispatch(gql_get_tprm_score(getScoreInput));

    dispatch(gql_get_risk_register(vendorBasicDetails?._id || storedVendorId));
  }, []);

  const calculateScores = () => {
    const totals = {};
    let grandTotal = 0;

    // Calculate total score for each group
    Object.entries(
      tprmScore?.["Human and Environmental Resilience"]?.company_profile?.["Company Profile"]
        ? tprmScore
        : defaultScores
    ).forEach(([group, items]) => {
      const groupTotal = Object.values(items).reduce((sum, item) => {
        const score = parseFloat(Object.values(item)[0]); // Extract the score as a float
        return sum + score;
      }, 0);

      totals[group] = groupTotal.toFixed(2); // Save each group total
      grandTotal += groupTotal; // Accumulate grand total
    });

    // Calculate the overall average
    const overallAverage = (grandTotal / 3).toFixed(2);

    return { totals, overallAverage };
  };

  return (
    <Box style={{ height: "63vh" }}>
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Risk Assessment
        </Typography>
      </Box>

      <Box className="scroll">
        {/* <Header />
        <br /> */}
        <OverallScore
          calculateScores={calculateScores}
          defaultScores={defaultScores}
        />
        <br />
        <TprmSubScoreTable
          calculateScores={calculateScores}
          defaultScores={defaultScores}
        />
        <br />
        <CompanyFindings />
      </Box>
    </Box>
  );
}

export default RiskAssessment;
