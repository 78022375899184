/*
Status types:
CIP: Complete, In progress, Pending
*/

const tableStatusColors = ({ type = "CIP", status }) => {
  switch (type) {
    case "CIP":
      return getCIPColor({ status });

    default:
      return { bgColor: "#BFE2CD", color: "#154328" };
  }
};

export default tableStatusColors;

export const getCIPColor = ({ status }) => {
  let bgColor = "#BFE2CD";
  let color = "#154328";

  const lowerStatus = status?.toLowerCase();

  if (lowerStatus === "in progress") {
    bgColor = "#ffeeba";
    color = "#856404";
  } else if (lowerStatus === "pending") {
    bgColor = "#EDCAC6";
    color = "#52231D";
  }

  return { bgColor, color };
};
