import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import DueDiligenceOverallScore from "./DueDiligenceOverallScore";
import CapacityAndAbility from "./CapacityAndAbility";
import ComplianceList from "./ComplianceList";
import CompilanceScore from "./ComplianceScore";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_survey_list_dataform,
  gql_get_survey_scores,
} from "../../../../redux/actions/vendorActions/vendorAction";
import DoraDocuments from "../../vendor/DoraDocuments";
import { gql_get_dora_compliance_results } from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";

function DueDiligence() {
  const dispatch = useDispatch();

  const [docId, setDocId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );

  const { doraComplianceResults, doraComplianceFiles } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { doraOverallScores } = doraComplianceFiles;

  const storedVendorId = localStorage.getItem("vendorId");

  const handleSelectedDoc = (id, pdfUrl, pdfName) => {
    setDocId(id);
    dispatch(gql_get_dora_compliance_results(id));
    setPdfUrl(pdfUrl);
    setPdfName(pdfName);

    console.log(id, pdfUrl, pdfName);
  };

  useEffect(() => {
    async function getSurveys() {
      await dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }

    if (surveyListDataForm?.["dueDiligenceSurveys"]?.length > 0) {
      getSurveys();
    }
  }, []);

  useEffect(() => {
    const surveyIds = surveyListDataForm?.["dueDiligenceSurveys"]?.map(
      (item) => item?._id
    );

    if (surveyListDataForm?.["dueDiligenceSurveys"]?.length > 0) {
      dispatch(
        gql_get_survey_scores(
          surveyIds[0],
          vendorBasicDetails?._id || storedVendorId
        )
      );
    }
  }, [surveyListDataForm]);

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  const customDoraResults =
    doraComplianceResults?.length > 0 ? [...doraComplianceResults] : [];

  return (
    <Box style={{ height: "63vh" }}>
      {docId === null && (
        <>
          <Box>
            <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
              Due Diligence
            </Typography>
          </Box>

          <Box className="scroll">
            {/* <Header />
        <br /> */}

            <DueDiligenceOverallScore severity={severity} />
            <br />
            <CapacityAndAbility severity={severity} />
            <br />
            <CompilanceScore score={doraOverallScores?.overallScore} />
            <br />
            <ComplianceList
              handleSelectedDoc={handleSelectedDoc}
              type={"Policy"}
            />
          </Box>
        </>
      )}
      {docId !== null && (
        <DoraDocuments
          setDocId={setDocId}
          docId={docId}
          pdfUrl={pdfUrl}
          pdfName={pdfName}
          fileData={customDoraResults[0]?.data}
        />
      )}
    </Box>
  );
}

export default DueDiligence;
