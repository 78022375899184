import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import VendorScopingQuestionnaire from "./VendorScopingQuestionnaire";
import RiskAssessment from "./RiskAssessment";
import DueDiligence from "./DueDiligence";
import Contracting from "./Contracting";
import Onboarding from "./Onboarding";
import Monitoring from "./Monitoring";
import TerminationAndOffboarding from "./TerminationAndOffboarding";

import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_bank_admin_single_survey,
  gql_get_survey_list_dataform,
} from "../../../redux/actions/vendorActions/vendorAction";

import ShareDialog from "../../screen/vendor/ShareDialog";
import OtherSurveys from "./OtherSurveys";
import ViewSurveyDrawer from "./viewSurveyDrawer/ViewSurveyDrawer";

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#3374B9",
    "&$checked": {
      color: "#3374B9",
    },
    "&$checked + $track": {
      backgroundColor: "#3374B9",
    },
  },
  checked: {},
  track: {},
  // button: {
  //   borderColor: "#3374B9",
  //   color: "#3374B9",
  //   "&:hover": {
  //     backgroundColor: "#e6f2ff",
  //     borderColor: "#3374B9",
  //   },
  // },
  chip: {
    backgroundColor: "#BFE2CD",
    color: "#195a11",
    // borderColor: "#195a11",
    // border: "1px solid",
    // "& .MuiChip-label": {
    //   color: "#195a11",
    // },
  },
}));

const SurveyQuestionsV2 = () => {
  const classes = useStyles();

  const [shareSurveyDrawer, setShareSurveyDrawer] = useState(false);
  const [viewSurveyDrawer, setViewSurveyDrawer] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const { surveyListDataForm, vendorBasicDetails, showSelectedSurvey } =
    useSelector((state) => state?.brmData);
  const dispatch = useDispatch();

  const storedVendorId = localStorage.getItem("vendorId");

  const handleShareSurveyDrawer = () => {
    setShareSurveyDrawer(false);
  };
  const handleViewSurveyDrawer = () => {
    setViewSurveyDrawer(false);
  };

  const handleGetSingleSurvey = (id) => {
    dispatch(gql_get_bank_admin_single_survey(id));
  };

  useEffect(() => {
    dispatch(
      gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
    );
  }, []);

  return (
    <Box style={{ height: "63vh" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Data Forms
        </Typography>
      </Box>

      <Box className="scroll">
        {surveyListDataForm?.["vendorScopingSurveys"]?.length > 0 && (
          <>
            <VendorScopingQuestionnaire
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["vendorScopingSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["riskAssessmentSurveys"]?.length > 0 && (
          <>
            <RiskAssessment
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["riskAssessmentSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["dueDiligenceSurveys"]?.length > 0 && (
          <>
            <DueDiligence
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["dueDiligenceSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["contractingSurveys"]?.length > 0 && (
          <>
            <Contracting
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["contractingSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["onboardingSurveys"]?.length > 0 && (
          <>
            <Onboarding
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["onboardingSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["monitoringSurveys"]?.length > 0 && (
          <>
            <Monitoring
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["monitoringSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
            <br />
          </>
        )}
        {surveyListDataForm?.["terminationSurveys"]?.length > 0 && (
          <>
            <TerminationAndOffboarding
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["terminationSurveys"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
          </>
        )}
        {surveyListDataForm?.["others"]?.length > 0 && (
          <>
            <OtherSurveys
              classes={classes}
              setShareSurveyDrawer={setShareSurveyDrawer}
              setViewSurveyDrawer={setViewSurveyDrawer}
              data={surveyListDataForm?.["others"]}
              setSurveyData={setSurveyData}
              handleGetSingleSurvey={handleGetSingleSurvey}
            />
          </>
        )}

        {/* Share Survey Drawer */}
        <ShareDialog
          shareDialogOpen={shareSurveyDrawer}
          handleClosShareDialog={handleShareSurveyDrawer}
          surveyData={surveyData}
        />

        {/* View Survey Drawer */}
        <ViewSurveyDrawer
          viewSurveyDrawer={viewSurveyDrawer}
          handleCloseViewDialog={handleViewSurveyDrawer}
          showSelectedSurvey={showSelectedSurvey}
          surveyData={surveyData}
        />
      </Box>
    </Box>
  );
};

export default SurveyQuestionsV2;
