import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Icon,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomButton from "../../../UI/button/button";

const data = [
  {
    MonitoringStartDate: "01-Jan-2024",
    PerformanceMetricsTracked: [
      "Uptime (99.8%)",
      "Transaction Processing Times",
    ],
    ComplianceAuditsConducted: "Yes",
    RiskReviewsConducted: "Quarterly",
    IssuesIdentified: "Yes",
    RemediationActionsTaken: "View Remediation Action",
    NextMonitoringReviewDate: "01-Oct-2024",
  },
  {
    MonitoringStartDate: "15-Feb-2024",
    PerformanceMetricsTracked: [
      "Service Delivery Times",
      "Data Processing Accuracy",
    ],
    ComplianceAuditsConducted: "Yes",
    RiskReviewsConducted: "Semi-Annual",
    IssuesIdentified: "No",
    RemediationActionsTaken: "View Remediation Action",
    NextMonitoringReviewDate: "15-Nov-2024",
  },
  {
    MonitoringStartDate: "10-Mar-2024",
    PerformanceMetricsTracked: [
      "Response Time (SLA Compliance)",
      "Customer Support Efficiency",
    ],
    ComplianceAuditsConducted: "No",
    RiskReviewsConducted: "Quarterly",
    IssuesIdentified: "Yes",
    RemediationActionsTaken: "View Remediation Action",
    NextMonitoringReviewDate: "10-Dec-2024",
  },
  {
    MonitoringStartDate: "05-Apr-2024",
    PerformanceMetricsTracked: ["Uptime (99.9%)", "Transaction Success Rate"],
    ComplianceAuditsConducted: "Yes",
    RiskReviewsConducted: "Quarterly",
    IssuesIdentified: "No",
    RemediationActionsTaken: "View Remediation Action",
    NextMonitoringReviewDate: "05-Jan-2025",
  },
  {
    MonitoringStartDate: "20-May-2024",
    PerformanceMetricsTracked: ["Service Downtime", "Error Rates"],
    ComplianceAuditsConducted: "Yes",
    RiskReviewsConducted: "Semi-Annual",
    IssuesIdentified: "Yes",
    RemediationActionsTaken: "View Remediation Action",
    NextMonitoringReviewDate: "20-Nov-2024",
  },
];

const IncidentOverview = () => {
  const [openRow, setOpenRow] = useState({}); // Store the open state of each row

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
        >
          Issue/Incident Overview
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#EBF1F8" }}>
            <TableRow>
              <TableCell>Monitoring Start Date</TableCell>
              <TableCell>Performance Metrics Tracked</TableCell>
              <TableCell>Compliance Audit Conducted</TableCell>
              <TableCell>Risk Review Conducted</TableCell>
              <TableCell>Issue Identified</TableCell>
              <TableCell>Remediation Actions Taken</TableCell>
              <TableCell>Next Monitoring Review Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{row.MonitoringStartDate}</TableCell>
                  <TableCell>{row.PerformanceMetricsTracked}</TableCell>
                  <TableCell>{row.ComplianceAuditsConducted}</TableCell>
                  <TableCell>{row.RiskReviewsConducted}</TableCell>
                  <TableCell>{row.IssuesIdentified}</TableCell>
                  <TableCell>
                    <CustomButton color="primary">View Action</CustomButton>
                  </TableCell>
                  <TableCell>{row.NextMonitoringReviewDate}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IncidentOverview;
