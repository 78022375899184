import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";

function VendorBackground({ classes }) {
  return (
    <Card style={{ marginBottom: "20px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>Vendor Background</Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />{" "}
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>

      <CardContent
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <Typography variant="body2">
          <strong>Vendor Name:</strong> PayWise
        </Typography>
        <Typography variant="body2">
          <strong>Service Provided:</strong> Payment gateway, point-of-sale
          (POS) solutions, and merchant acquiring services
        </Typography>
        <Typography variant="body2">
          <strong>Headquarters:</strong> Noida, India
        </Typography>
        <Typography variant="body2">
          <strong>Operational Regions:</strong> India, United States, United
          Kingdom, Singapore, Malaysia
        </Typography>
        <Typography variant="body2">
          <strong>Engagement Start Date:</strong> 01-Dec-2022
        </Typography>
        <Typography variant="body2">
          <strong>Department Engaged:</strong> Retail Banking
        </Typography>
        <Typography variant="body2">
          <strong>Service Criticality:</strong> High (Payment processing and
          merchant services are integral to banking operations)
        </Typography>
        <Typography variant="body2">
          PayWise is a payment solutions provider headquartered in Noida, India,
          with a team of over 500 employees. In addition to its operations in
          India, PayWise has expanded into the United States, the United
          Kingdom, Singapore, and Malaysia. The vendor serves the retail banking
          sector, delivering essential payment gateway services, POS solutions,
          and merchant acquiring services to clients across multiple regions.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default VendorBackground;
