import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Paper,
  RadioGroup,
  Tab,
  TableCell,
  Tabs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CustomButton from "../../UI/button/button";
import TabPanel from "../../UI/tabPanel/tabPanel";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../UI/textfield/textfield";
import countryList from "../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import tickGreen from "../../../../images/TickGreen.png";
// import Add from "@material-ui/icons/Add";
// import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
// import RiskAnalysisRightTab from "../../riskAnalysisRightTab/riskAnalysisRightTab";
import { corporateSurveyQuestions } from "../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";
// import SentimentAnalysisRightTab from "../../sentimentAnalysisRightTab/sentimentAnalysisRightTab";
// import RiskSummaryTab from "../../riskSummaryTab/riskSummaryTab";
// import { addMember, viewMember } from "../../../../redux/actions/member/member";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import SurveyQuestionsV2 from "../../widgets/surveyQuestions_v2/SurveyQuestionsV2";
// import ControverciesQuestions from "../../controverciesQuestions/controverciesQuestions";
// import EsgDeclaration from "../../esgDeclaration/esgDeclaration";
// import Controversy from "../../esgControversy/controversy";
// import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
// import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
// import PeerDocuments from "../../publicInfo/peerDocuments";
// import PublicReports from "../../publicInfo/publicReports";
import ApiConnections from "../../widgets/publicInfo/apiConnections";
import Documents from "../../widgets/publicInfo/documents";
// import { ChartRenderer } from "../../charts/charts";
// import BasicAssessmentDetails from "../../riskAnalysisRightTab/basicAssessmentDetails";
import GetApp from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";

import { appTheme } from "../../widgets/riskAssessment/brmDetaails";
import { BlueBackgroundCard } from "../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import NewsArticle from "../../widgets/esgControversy/newsArticle";
import ControversyChart from "../../widgets/controversiesTab/ChartSection";
import InterestMap from "../../widgets/controversiesTab/MapSection";
import ControversyProfile from "../../widgets/esgControversy/controversyProfile";
import { TurnoverYearPickerDialog } from "../../widgets/riskAssessment/riskAssessment";
import StakeholderList from "../../widgets/riskAssessment/stakeholderList";
import { UserCard } from "../../widgets/riskAssessment/riskUtilComponents";
import EsgRiskRating from "../../widgets/esgRiskRating/esgRiskRating";
import DoraCompilance from "../../widgets/vendor/doraCompilance";
import VendorProfile from "../../widgets/vendor/vendorProfile";
import SurveyResponses from "../../widgets/surveyResponses/surveyResponses";
import ThirdPartyRiskRating from "../../widgets/esgRiskRating/ThirdPartyRiskRating";
import EsgCompantProfile from "../../widgets/esgCompanyProfile/esgCompantProfile";
import DataBreakdown from "../../widgets/riskAssessment/DataBreakdown";
import {
  gql_add_new_stakeholder,
  gql_get_all_stakeholders,
  gql_get_bank_admin_surveys,
  gql_get_vendor_basic_details,
  gql_update_vendor,
  updateVendorOrg,
} from "../../../redux/actions/vendorActions/vendorAction";
import { useDispatch } from "react-redux";
import { dummyIndustries } from "../../../utils/riskUtils/riskUtils";
import {
  response1,
  response2,
} from "../../widgets/riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import ShareDialog from "./ShareDialog";
import Insights from "../../widgets/controversiesTab/Insights";
import RegisterOfInformation from "../../widgets/riskAssessment/RegisterOfInformation";
import { set_snack_bar } from "../../../redux/actions/snackbar/snackbar_action";
import VendorMateriality from "../../widgets/riskAssessment/VendorMateriality";
import VendorPerformance from "../../widgets/riskAssessment/VendorPerformance";
import VendorCategorization from "../../widgets/riskAssessment/VendorCategorization";
import ApiConnectionV2 from "../../widgets/publicInfo/apiConnection_v2/ApiConnectionV2";
import VendorIntake from "../../widgets/vendorDiagnosis_v2/vendorIntake";
import RiskAssessment from "../../widgets/vendorDiagnosis_v2/riskAssessment";
import DueDiligence from "../../widgets/vendorDiagnosis_v2/dueDiligence";
import Contracting from "../../widgets/vendorDiagnosis_v2/contracting";
import Onboarding from "../../widgets/vendorDiagnosis_v2/onboarding";
import Monitoring from "../../widgets/vendorDiagnosis_v2/monitorning";
import TerminationAndOffboarding from "../../widgets/vendorDiagnosis_v2/terminationAndOffboarding";
import SurveyQuestions from "../../widgets/surveyQuestions/surveyQuestions";
import VendorBasicDetails from "../../widgets/companySetup/vendorBasicDetails/VendorBasicDetails";
import OngoingAssessment from "../../widgets/companySetup/ongoingAssessment/OngoingAssessment";
import TprmRiskReport from "../../widgets/tprmRisk/trpmRiskReport/TprmRiskReport";

export const brmRightStyles = makeStyles((theme) => ({
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },
  cardContainerDefault: {
    width: "100%",
    height: "100px",
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },

  cardTypography: {
    fontWeight: "600",
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12, textTransform: "none" },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typographyshare: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  onListHover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
}));

function VendorRight({ linkageSurveyId, ...props }) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  console.log(params);

  const classes = brmRightStyles();
  const {
    brmData,
    riskEvaluator,
    riskTabNo,
    setRiskTabNo,
    setOrganizationDetails,
    login,
  } = props;

  const dispatch = useDispatch();

  const [turnoverData, setTurnoverData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  useEffect(() => {
    // brmData?.organizationDetails[0] &&
    //   setOrganizaitonDetailsObject(brmData?.organizationDetails[0]);
    // setCompanyLogoURI(brmData?.organizationDetails[0]?.logo?.url);
    // setTurnoverData(brmData?.organizationDetails[0]?.turnover || []);
    // setRevenueData(brmData?.organizationDetails[0]?.revenue || []);

    const storedVendorId = localStorage.getItem("vendorId");

    dispatch(
      gql_get_vendor_basic_details(location?.state?.vendorId || storedVendorId)
    );
  }, []);

  const {
    _id: vendorId,
    name: vendorName,
    website,
    logo: vendorLogo,
    sector: selectedSector,
    operation,
    employeesCount,
    address,
    revenue,
    turnover,
    vendoriq_id,
  } = brmData?.vendorBasicDetails;

  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    userName: "",
    userDesignation: "",
    no_of_employees: "",
    isin: "",
    website: "",
    logo: "",
    sector: {
      industry: "",
      sector: "",
    },
    address: {
      landMark: "",
      state: "",
      pinCode: "",
      country: "",
      companyAddress: "",
    },
  });

  useEffect(
    function () {
      const industry = selectedSector?.industry;
      const sector = selectedSector?.sector;

      setOrganizaitonDetailsObject((prevState) => {
        return {
          name: vendorName,
          operation: operation,
          userName: "",
          userDesignation: "",
          no_of_employees: employeesCount,
          isin: "",
          website: website,
          sector:
            selectedSector === undefined
              ? { industry: "", sector: "" }
              : { industry: industry?.industry, sector: sector?.sector },
          logo: vendorLogo === undefined ? "" : vendorLogo?.url,
          address:
            address === undefined
              ? {
                  landMark: "",
                  state: "",
                  pinCode: "",
                  country: "",
                  companyAddress: "",
                }
              : {
                  landMark: address?.landmark || "",
                  state: address?.state || "",
                  pinCode: address?.pincode || "",
                  country: address?.country || "",
                  companyAddress: address?.location || "",
                },
        };
      });

      setRevenueData(revenue);
      setTurnoverData(turnover);

      // return () => {
      //   dispatch({ type: SET_VENDOR_BASIC_DETAILS, payload: {} });
      // };
      localStorage.setItem("vendoriq_id", vendoriq_id);
    },
    [brmData?.vendorBasicDetails, brmData?.vendorBasicDetails?._id]
  );

  const [selectedSurveyData, setSelectedSurveyData] = useState({});

  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [userId, setUserId] = useState("");

  const [role, setRole] = useState("");
  const [reportsTab, setReportsTab] = useState(0);

  const [isSelectedSector, setIsSelectedSector] = useState("");

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      // uploadUserSheet(login.token, login.current_organisation, file);
      setAddNewUserDialogOpen(false);
    }
  };
  const stakeHolderCategoryList = [
    "Board of Directors",
    "Executive Team",
    "Employees",
    "Investors & Shareholders",
    "Suppliers & Vendors",
    "Consultants",
    "Customers",
    "Government Agencies",
  ];
  const reportData = [
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
  ];
  const breakDown = [
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        { question: "Growth", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        {
          question: "cross region",
          FY2020: "2330",
          FY2021: "2331",
          FY20202: "2332",
        },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "sub question",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
            {
              question: "time",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
          ],
        },
      ],
    },
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", Fy2021: "2331", Fy20202: "2332" },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "Europe",
              FY2020: "2330",
              Fy2021: "2331",
              Fy20202: "2332",
            },
          ],
        },
      ],
    },
  ];

  async function handleAddUser() {
    // props.addMember(
    //   login?.token,
    //   login?.bankId,
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id"),
    //   name,
    //   email,
    //   role,
    //   category,
    //   type,
    //   window.location.origin
    // );

    const vendorId = brmData.organizationDetails[0]._id;

    if (!validateEmail(email)) {
      dispatch(set_snack_bar(true, "Please enter a valid email"));
    } else if (name === "") {
      dispatch(set_snack_bar(true, "please enter the name"));
    } else if (category === "") {
      dispatch(set_snack_bar(true, "please enter the category"));
    } else if (role === "") {
      dispatch(set_snack_bar(true, "please enter the role"));
    } else {
      await dispatch(
        gql_add_new_stakeholder(email, name, vendorId, category, role)
      );

      dispatch(gql_get_all_stakeholders(vendorId, "EXTERNAL"));

      setEmail("");
      setName("");
      setCategory("");
      setRole("");

      setAddNewUserDialogOpen(false);
    }

    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData.organizationDetails[0]._id
    //     ? brmData.organizationDetails[0]._id
    //     : localStorage.getItem("organization_id")
    // );
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // setMembers(brmData.members);
  }

  React.useEffect(() => {
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
  }, []);

  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>
                Add New Stakeholder
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            {/* <Grid item md={12} className={classes.grid3}>
              <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a>
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="secondary"
                  component="label"
                >
                  Upload Excel
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<GetApp />}
                  className={classes.CustomButton2}
                  variant="contained"
                  size="small"
                  color="secondary"
                  component="label"
                >
                  Download Excel
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>*/}
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                error={email !== "" && !validateEmail(email)}
                helperText={
                  !validateEmail(email) &&
                  email !== "" &&
                  "Please enter a valid email"
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setAddNewUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                // disabled={
                //   name === "" ||
                //   !validateEmail(email) ||
                //   category === "" ||
                //   role === ""
                // }
                onClick={handleAddUser}
              >
                Add
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const editUserDialog = () => {
    return (
      <Dialog open={editUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Edit User</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              {/* <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a> */}
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setEditUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" ||
                  !validateEmail(email) ||
                  category === "" ||
                  role === ""
                }
                onClick={() => {
                  props.editMember(
                    login.token,
                    userId,
                    brmData?.organizationDetails[0]?._id
                      ? brmData?.organizationDetails[0]?._id
                      : localStorage.getItem("organization_id"),
                    role,
                    category
                  );
                  setEditUserDialogOpen(false);
                }}
              >
                Update User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });
  // const shareDialog = () => {
  //   return (
  //     <Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
  //       <DialogTitle>
  //         <Grid container justifyContent="center" className={classes.mainGrid2}>
  //           <Grid item xs={4}>
  //             <Typography className={classes.Typographyshare}>
  //               Share Survey
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </DialogTitle>
  //       <DialogContent>
  //         <Grid
  //           container
  //           spacing={1}
  //           direction="column"
  //           alignItems="flex-start"
  //           // style={{}}
  //         >
  //           <Grid item md={12} className={classes.grid8}>
  //             <Paper elevation={0} className={classes.Paper}>
  //               <Autocomplete
  //                 size="small"
  //                 value={emails}
  //                 options={
  //                   brmData.members && brmData.members.length > 0
  //                     ? brmData.members.map((mem) => mem.email)
  //                     : []
  //                 }
  //                 // options={maturityMatrix.notAssignedUsers.map(
  //                 //   (user) => user.email
  //                 // )}
  //                 // renderTags={(value, getTagProps) => {
  //                 //   return value.map((option, index) => (
  //                 //     <>
  //                 //       <Chip
  //                 //         variant="outlined"
  //                 //         label={option}
  //                 //         {...getTagProps({ index })}
  //                 //       />
  //                 //     </>
  //                 //   ));
  //                 // }}
  //                 onChange={(e, newValue) => {

  //                   setEmails(newValue);
  //                 }}
  //                 renderInput={(params) => (
  //                   <>
  //                     <CustomTextfield
  //                       {...params}
  //                       // onChange={(e, newValue) => {

  //                       //   // setEmails(newValue);
  //                       // }}
  //                       size="small"
  //                       variant="outlined"
  //                       fullWidth
  //                       label="Search By Emai ID or Name"
  //                       name="asgEmailStep3"
  //                     />
  //                   </>
  //                 )}
  //               />
  //             </Paper>
  //           </Grid>
  //           {true && (
  //             <>
  //               <Grid item className={classes.grid8}>
  //                 <CustomTextfield
  //                   label="Email Subject"
  //                   name="subject"
  //                   fullWidth
  //                   size="small"
  //                   variant="outlined"
  //                   style={{ marginTop: 10 }}
  //                   // onChange={stakeHolderContentChange}
  //                   helperText="hint : survey ESG requires contribution"
  //                 />
  //               </Grid>
  //               <Grid item className={classes.grid8}>
  //                 <Typography className={classes.Typography5}>
  //                   Content
  //                 </Typography>
  //               </Grid>
  //               <Grid item className={classes.grid8}>
  //                 <CustomTextfield
  //                   name="content"
  //                   multiline="true"
  //                   onChange={(e) => {
  //                     // stakeHolderContentChange(e);
  //                   }}
  //                   className={classes.CustomTextfield}
  //                   defaultValue={`hello user,  Hope this mail finds you in a good place. kindly fillout the survey form to get thr esf in relation to your company.`}
  //                   fullWidth
  //                   variant="outlined"
  //                   inputProps={{ className: classes.shareContentText }}
  //                 />
  //               </Grid>
  //             </>
  //           )}
  //         </Grid>
  //       </DialogContent>

  //       <DialogActions>
  //         <Grid container spacing={2} className={classes.grid7}>
  //           <Grid item xs={12} md={12} className={classes.grid3}>
  //             <CustomButton
  //               color="primary"
  //               // variant="outlined"
  //               className={classes.CustomButton3}
  //               onClick={() => {
  //                 setShareDialogOpen(false);
  //                 // setEmails([]);
  //               }}
  //             >
  //               Cancel
  //             </CustomButton>
  //             <CustomButton
  //               color="primary"
  //               variant="contained"
  //               className={classes.CustomButton3}
  //               onClick={() => {
  //                 // handleShareAssessment();
  //                 // setCheckedState(
  //                 //   new Array(generalSettings.allUsers.length).fill(false)
  //                 // );shareSurvey: (token, organization_id, email) => {

  //                 props.shareSurvey(
  //                   localStorage.getItem("user_token")
  //                     ? localStorage.getItem("user_token")
  //                     : props?.login?.token,
  //                   brmData?.organizationDetails[0]?._id
  //                     ? brmData?.organizationDetails[0]?._id
  //                     : localStorage.getItem("organization_id"),
  //                   emails && emails
  //                 );

  //                 setShareDialogOpen(false);
  //               }}
  //             >
  //               Send Survey
  //             </CustomButton>
  //           </Grid>
  //           {/* <Grid item xs={4} md={4} className={classes.grid6}>

  //           </Grid> */}
  //         </Grid>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  useEffect(() => {
    if (props.isEvaluator) {
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 0 &&
        props.getScores(login.token, brmData?.organizationDetails[0]?.isin);
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 3 &&
        props.getControversyData(
          login.token,
          brmData?.organizationDetails[0]?.isin
        );
    }
  }, [brmData?.brmMainTabNo, brmData?.brmSubTabNo]);

  const [sectors, setsectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const [allResponseArray, setAllResponseArray] = useState([]);

  useEffect(() => {
    if (
      brmData?.organizationDetails &&
      brmData?.organizationDetails[0]?.sectors &&
      brmData.organizationDetails[0]?.sectors?.length > 0
    ) {
      const dummy = JSON.parse(JSON.stringify(dummyIndustries));
      let masterSectors = [...dummy];
      masterSectors.map((masterSec, masterSecIdx) => {
        return (
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map((basicInd) =>
                masterInd._id === basicInd
                  ? (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = true)
                  : (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = false)
              )
            )
          )
        );
      });

      setsectors(masterSectors);
    } else {
      const dummy = JSON.parse(JSON.stringify(dummyIndustries));
      setsectors(dummy);
    }
  }, [brmData.sectorIndustries, brmData.organizationDetails]);

  useEffect(() => {
    return () => {
      setsectors([]);
      // setOrganizationDetails("");
    };
  }, []);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));

    sectorL = sectorL.map((sector) => ({
      ...sector,
      industry_data: sector.industry_data.map((industry) => ({
        ...industry,
        isSelected: false,
      })),
    }));

    // Select the clicked industry
    sectorL[sectorIndex].industry_data[industryIndex].isSelected = true;

    // Unselect all other sectors
    sectorL = sectorL.map((sector, index) => ({
      ...sector,
      isSelected: index === sectorIndex,
    }));
    // ----------------------------------------------------------------
    setsectors(sectorL);

    setIsSelectedSector(sectorIndex);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={() => setIsSectorDialogOpen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onChange={() => handleIndustryChange(index2, index)}
                          >
                            {/* {industry.isSelected} */}
                            <CustomCheckBox
                              key={industry.industry + index2}
                              checked={industry.isSelected ? true : false}
                              name={industry.Industry}
                            />
                            <Typography id={industry.Industry}>
                              {industry.Industry}
                            </Typography>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsSectorDialogOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleEditUser = (user) => {
    setUserId(user._id);
    setEditUserDialogOpen(true);
    setName(user.name);
    setEmail(user.email);
    setCategory(user.category);
    setRole(user.role);
  };

  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
    setOrganizaitonDetailsObject((prevState) => ({
      ...prevState,
      logo: "",
    }));
  };

  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    setCompanyLogo(file);

    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    organizaitonDetailsObject.year_of_assessment &&
      setStartYear(organizaitonDetailsObject.year_of_assessment?.split("-")[0]);
    organizaitonDetailsObject.year_of_assessment &&
      setEndYear(organizaitonDetailsObject.year_of_assessment?.split("-")[1]);
  }, [organizaitonDetailsObject.year_of_assessment]);

  useEffect(() => {
    const data = [];
    if (!organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(
          organizaitonDetailsObject.year_of_assessment.split("-")[0] - 3 + i
        );
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  year_of_assessment: "",
                }));
                organizaitonDetailsObject.year_of_assessment &&
                  setStartYear(
                    organizaitonDetailsObject.year_of_assessment.split("-")[0]
                  );
                organizaitonDetailsObject.year_of_assessment &&
                  setEndYear(
                    organizaitonDetailsObject.year_of_assessment.split("-")[1]
                  );
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  year_of_assessment: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [turnoverPicker]);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  const handleSetYear = () => {
    !currentIndex.current.revenue &&
      setTurnoverData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };
  const { vendorTabNo, vendorSubTabNo } = props.vendor;

  const [url, setUrl] = useState();
  const [showURLHelper, setShowURLHelper] = useState(false);
  const [showWebHelper, setShowWebHelper] = useState(false);

  const validURL = (value) => {
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    return urlRegex.test(value);
  };

  const handleWebsiteChange = (e) => {
    setOrganizaitonDetailsObject((prev) => ({
      ...prev,
      website: e.target.value,
    }));

    if (e.target.value && e.target.value !== "") {
      if (validURL(e.target.value)) {
        setShowWebHelper(false);
      } else {
        setShowWebHelper(true);
      }
    } else {
      setShowWebHelper(false);
    }
  };

  // peers
  const [peers, setPeers] = useState([]);

  const handleAddPeer = () => {
    setPeers([...peers, { peer: null, domainName: "" }]);
  };

  const handleRemovePeer = (index) => {
    const updatedPeers = [...peers];
    updatedPeers.splice(index, 1);
    setPeers(updatedPeers);
  };

  const handlePeerChange = (index, value) => {
    const updatedPeers = [...peers];
    updatedPeers[index] = { ...updatedPeers[index], option: value };
    setPeers(updatedPeers);
  };

  const handleDomainChange = (index, domainName) => {
    const updatedPeers = [...peers];
    updatedPeers[index] = { ...updatedPeers[index], domainName: domainName };
    setPeers(updatedPeers);
  };

  useEffect(() => {
    if (url && url !== "") {
      if (validURL(url)) {
        setShowURLHelper(false);
      } else {
        setShowURLHelper(true);
      }
    } else {
      setShowURLHelper(false);
    }
  }, [url]);

  const filteredSector = sectors?.map((e) => {
    let indData = [];
    e?.industry_data.map((ind) => {
      if (ind?.isSelected === true) {
        indData.push(ind._id);
      }
      return ind;
    });

    if (indData.length > 0)
      return {
        sector: e._id,
        industry: indData, // set industry to indData only for multiple industry selection
      };
    return false;
  });

  // ------------- update brm organization data for vendor -------------

  const [shareStatus, setShareStatus] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    localStorage.setItem("companyName", params?.companyName);
    localStorage.setItem("lastPath", "/vendor/:companyName");

    if (vendorSubTabNo === 0 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/basic_details");
      history.push(`/vendor/${params?.companyName}/basic_details`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/stakeholders");
      history.push(`/vendor/${params?.companyName}/stakeholders`);
    } else if (vendorSubTabNo === 2 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/register_of_information");
      history.push(`/vendor/${params?.companyName}/register_of_information`);
    } else if (vendorSubTabNo === 3 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/vendor_materiality");
      history.push(`/vendor/${params?.companyName}/vendor_materiality`);
    } else if (vendorSubTabNo === 4 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/vendor_performance");
      history.push(`/vendor/${params?.companyName}/vendor_performance`);
    } else if (vendorSubTabNo === 5 && vendorTabNo === 0) {
      localStorage.setItem("selectedTab", "/vendor/vendor_categorization");
      history.push(`/vendor/${params?.companyName}/vendor_categorization`);
    }

    // Vendor Tab No 2
    if (vendorSubTabNo === 0 && vendorTabNo === 1) {
      localStorage.setItem("selectedTab", "/vendor/api_connection");
      history.push(`/vendor/${params?.companyName}/api_connection`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 1) {
      localStorage.setItem("selectedTab", "/vendor/survey");
      history.push(`/vendor/${params?.companyName}/survey`);
    } else if (vendorSubTabNo === 2 && vendorTabNo === 1) {
      localStorage.setItem("selectedTab", "/vendor/documents");
      history.push(`/vendor/${params?.companyName}/documents`);
    }

    // Vendor Tab No 3
    if (vendorSubTabNo === 0 && vendorTabNo === 2) {
      localStorage.setItem("selectedTab", "/vendor/vendor_profile");
      history.push(`/vendor/${params?.companyName}/vendor_profile`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 2) {
      localStorage.setItem("selectedTab", "/vendor/document_compliance");
      history.push(`/vendor/${params?.companyName}/document_compliance`);
    } else if (vendorSubTabNo === 2 && vendorTabNo === 2) {
      localStorage.setItem("selectedTab", "/vendor/vendor_survey_responses");
      history.push(`/vendor/${params?.companyName}/vendor_survey_responses`);
    }

    // Vendor Tab No 4
    if (vendorSubTabNo === 0 && vendorTabNo === 3) {
      localStorage.setItem("selectedTab", "/vendor/esg_profile");
      history.push(`/vendor/${params?.companyName}/esg_profile`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 3) {
      localStorage.setItem("selectedTab", "/vendor/esg_kpis");
      history.push(`/vendor/${params?.companyName}/esg_kpis`);
    } else if (vendorSubTabNo === 2 && vendorTabNo === 3) {
      localStorage.setItem("selectedTab", "/vendor/esg_survey_responses");

      history.push(`/vendor/${params?.companyName}/esg_survey_responses`);
    }

    // Vendor Tab No 5
    if (vendorSubTabNo === 0 && vendorTabNo === 4) {
      localStorage.setItem("selectedTab", "/vendor/digital_reputation");

      history.push(`/vendor/${params?.companyName}/digital_reputation`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 4) {
      localStorage.setItem("selectedTab", "/vendor/articles");

      history.push(`/vendor/${params?.companyName}/articles`);
    } else if (vendorSubTabNo === 2 && vendorTabNo === 4) {
      localStorage.setItem("selectedTab", "/vendor/insights");

      history.push(`/vendor/${params?.companyName}/insights`);
    }

    // Vendor Tab No 5
    if (vendorSubTabNo === 0 && vendorTabNo === 5) {
      localStorage.setItem("selectedTab", "/vendor/third_party_risk");

      history.push(`/vendor/${params?.companyName}/third_party_risk`);
    } else if (vendorSubTabNo === 1 && vendorTabNo === 5) {
      localStorage.setItem("selectedTab", "/vendor/esg_risk");

      history.push(`/vendor/${params?.companyName}/esg_risk`);
    }
  }, [history, vendorSubTabNo, vendorTabNo]);

  // useEffect(() => {
  //   if (localStorage.getItem("selectedTab") === "/vendor/stakeholders") {
  //     history.push(`/vendor/${params?.companyName}/stakeholders`);
  //   }
  // }, []);

  return (
    <div>
      {yearPickerDialog()}
      {isSectorDialogOpen && sectorIndustry()}
      {shareDialogOpen && (
        <ShareDialog
          shareDialogOpen={shareDialogOpen}
          handleClosShareDialog={() => setShareDialogOpen(false)}
          setShareStatus={setShareStatus}
          tab={tab}
        />
      )}
      {addNewUserDialogOpen && addNewUserDialog()}
      {editUserDialogOpen && editUserDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
      >
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />

        {
          <CardContent className={classes.cardContent}>
            {/* COMPANY SETUP RIGHT TABS */}
            {vendorTabNo === 0 && vendorSubTabNo === 0 && (
              <VendorBasicDetails />
            )}
            {vendorTabNo === 0 && vendorSubTabNo === 1 && (
              <StakeholderList
                setAddNewUserDialogOpen={setAddNewUserDialogOpen}
                handleEditUser={handleEditUser}
                brmData={brmData}
                vendorTabNo={vendorTabNo}
                vendorSubTabNo={vendorSubTabNo}
              />
            )}
            {vendorTabNo === 0 && vendorSubTabNo === 2 && <OngoingAssessment />}
            {vendorTabNo === 0 && vendorSubTabNo === 3 && (
              <RegisterOfInformation
                setAllResponseArray={setAllResponseArray}
                allResponseArray={allResponseArray}
                isFrom={"editVendor"}
              />
            )}
            {vendorTabNo === 0 && vendorSubTabNo === 4 && (
              <VendorMateriality
                linkageSurveyId={linkageSurveyId}
                vendorSubTabNo={vendorSubTabNo}
              />
            )}
            {vendorTabNo === 0 && vendorSubTabNo === 5 && (
              <VendorPerformance
                linkageSurveyId={linkageSurveyId}
                vendorSubTabNo={vendorSubTabNo}
              />
            )}
            {vendorTabNo === 0 && vendorSubTabNo === 6 && (
              <VendorCategorization
                linkageSurveyId={linkageSurveyId}
                vendorSubTabNo={vendorSubTabNo}
              />
            )}

            {/*         {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                getSurveyeQuestions={props.getSurveyeQuestions}
                setShareDialogOpen={setShareDialogOpen}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )} */}
            {/* 
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 3 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                setShareDialogOpen={setShareDialogOpen}
                getSurveyeQuestions={props.getSurveyeQuestions}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )} */}

            {/* <div className="scroll" style={{ marginTop: 18 }}> */}

            {vendorTabNo === 1 && vendorSubTabNo === 0 && <ApiConnectionV2 />}

            {/* {vendorTabNo === 1 && vendorSubTabNo === 1 && (
              <SurveyQuestions
                {...props}
                setSelectedSurveyData={setSelectedSurveyData}
                selectedSurveyData={selectedSurveyData}
                setTab={setTab}
                tab={tab}
                setShareDialogOpen={setShareDialogOpen}
              />
            )} */}
            {vendorTabNo === 1 && vendorSubTabNo === 1 && <SurveyQuestionsV2 />}
            {vendorTabNo === 1 && vendorSubTabNo === 2 && (
              <Documents riskEvaluator={riskEvaluator} {...props} />
            )}

            {vendorTabNo === 2 && vendorSubTabNo === 0 && <VendorIntake />}
            {vendorTabNo === 2 && vendorSubTabNo === 1 && <RiskAssessment />}
            {vendorTabNo === 2 && vendorSubTabNo === 2 && <DueDiligence />}
            {vendorTabNo === 2 && vendorSubTabNo === 3 && <Contracting />}
            {vendorTabNo === 2 && vendorSubTabNo === 4 && <Onboarding />}
            {vendorTabNo === 2 && vendorSubTabNo === 5 && <Monitoring />}
            {vendorTabNo === 2 && vendorSubTabNo === 6 && (
              <TerminationAndOffboarding />
            )}

            {vendorTabNo === 3 && vendorSubTabNo === 0 && (
              <EsgCompantProfile {...props} />
            )}
            {vendorTabNo === 3 && vendorSubTabNo === 1 && (
              <DataBreakdown {...props} />
            )}
            {vendorTabNo === 3 && vendorSubTabNo === 2 && (
              <SurveyResponses vendorTabNo={vendorTabNo} />
            )}

            {vendorTabNo === 4 && vendorSubTabNo === 0 && (
              <div
                style={{
                  height: "63vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                    Digital Reputation
                  </Typography>
                  <Icon
                    style={{
                      cursor: "pointer",
                      color: "#3374b9",
                      fontSize: 20,
                    }}
                  >
                    more_vert
                  </Icon>
                </div>
                <div
                  className="scroll"
                  style={{
                    marginTop: "10px",
                    paddingBottom: 20,
                  }}
                >
                  <ControversyProfile riskEvaluator={riskEvaluator} />
                </div>
              </div>
            )}
            {vendorTabNo === 4 && vendorSubTabNo === 1 && (
              <NewsArticle {...props} />
            )}
            {vendorTabNo === 4 && vendorSubTabNo === 2 && (
              <div style={{ height: "63vh" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                    Insights
                  </Typography>
                  <Icon
                    style={{
                      cursor: "pointer",
                      color: "#3374b9",
                      fontSize: 20,
                    }}
                  >
                    more_vert
                  </Icon>
                </div>
                <div className="scroll" style={{ marginTop: "10px" }}>
                  <Insights />
                </div>
              </div>
            )}
            {vendorTabNo === 5 && vendorSubTabNo === 0 && <TprmRiskReport />}
            {vendorTabNo === 5 && vendorSubTabNo === 1 && (
              <ThirdPartyRiskRating />
            )}
            {/* </div> */}
          </CardContent>
        }
      </Card>
    </div>
  );
}

export default VendorRight;
