import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";

function CapacityAndAbility({ severity }) {
  const { surveyScores } = useSelector((state) => state.brmData);

  const nameFormat = (name) => {
    if (name === "securityAndRiskManagement") {
      return "Security and Risk Management";
    } else if (name === "complianceAndFinancialStability") {
      return "Compliance and Financial Stability";
    }
  };

  const defaultData = {
    securityAndRiskManagement: {
      comment: "",
      score: surveyScores?.securityAndRiskManagement?.totalScore,
    },
    complianceAndFinancialStability: {
      comment: "",
      score: surveyScores?.complianceAndFinancialStability?.totalScore,
    },
  };

  const scoresDefaultData = {
    securityAndRiskManagement: {
      "Organizational Security":
        surveyScores?.securityAndRiskManagement?.["Organizational Security"],
      "Cybersecurity Risks":
        surveyScores?.securityAndRiskManagement?.["Cybersecurity Risks"],
      "Data Security and Privacy":
        surveyScores?.securityAndRiskManagement?.["Data Security and Privacy"],
      "Incident Response and Management":
        surveyScores?.securityAndRiskManagement?.[
          "Incident Response and Management"
        ],
      "Network, Application, and Information Security":
        surveyScores?.securityAndRiskManagement?.[
          "Network, Application, and Information Security"
        ],
      "Fourth-Party or Supply Chain Risk Management":
        surveyScores?.securityAndRiskManagement?.[
          "Fourth-Party or Supply Chain Risk Management"
        ],
    },
    complianceAndFinancialStability: {
      "Compliance and Certifications":
        surveyScores?.complianceAndFinancialStability?.[
          "Compliance and Certifications"
        ],
      "Financial Information":
        surveyScores?.complianceAndFinancialStability?.[
          "Financial Information"
        ],
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {defaultData &&
        Object?.entries(defaultData)?.map(([key, value], index) => (
          <Grid md={6}>
            <BlueBackgroundCard heading={nameFormat(key)} />
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"15px"}
              marginLeft={"20px"}
            >
             {value?.score && <Chip
                label={
                  parseFloat(value?.score) <= 33
                    ? "Low"
                    : parseFloat(value?.score) > 33 &&
                      parseFloat(value?.score) <= 66
                    ? "Mid"
                    : "High"
                }
                style={{
                  color:
                    parseFloat(value?.score) <= 33
                      ? severity("text", "red")
                      : parseFloat(value?.score) > 33 &&
                        parseFloat(value?.score) <= 66
                      ? severity("text", "yellow")
                      : severity("text", "green"),
                  backgroundColor:
                    parseFloat(value?.score) <= 33
                      ? severity("bg", "red")
                      : parseFloat(value?.score) > 33 &&
                        parseFloat(value?.score) <= 66
                      ? severity("bg", "yellow")
                      : severity("bg", "green"),
                }}
                size="small"
              />}
              <Typography
                style={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "40px" }}>{value?.score || "-"}</span>
                <span style={{ fontSize: "20px" }}>/</span>
                <span>100</span>
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "13px",
                padding: "5px",
                marginBottom: "10px",
              }}
              // component={Paper}
            >
              The score reflects the vendor's performance and can guide them in
              making informed decisions.
            </Typography>
            <Grid justifyContent="space-around">
              <Grid item md={5}>
                {/* <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  /> */}
              </Grid>
              <Grid item md={5}>
                <div
                  key={index}
                  style={{
                    width: "95%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    marginTop: "20px",
                  }}
                >
                  <TopicHeader
                    topic={nameFormat(key)}
                    // score={value?.score}
                    noIcon
                  />
                  {(value?.sectionWiseScore || scoresDefaultData) &&
                    Object.entries(
                      value?.sectionWiseScore ?? scoresDefaultData[key]
                    )?.map(([section, score], i) => (
                      <TopicData
                        key={i}
                        topic={section}
                        // score={score}
                        textValue={score}
                        marginTop={"10px"}
                      />
                    ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default CapacityAndAbility;
