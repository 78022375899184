import UserImage from "../../../images/DP.png";

export const bankHeadings = [
  { name: "Banks Name", showSort: true },
  { name: "No. Of Dataforms", showSort: true },
  { name: "Branch", showSort: true },
  { name: "Status", showSort: true },
  // { name: "View Profile", showSort: false },
];

export const vendorHeadings = [
  { name: "Vendors Name", showSort: true },
  { name: "No. Of Dataforms", showSort: true },
  { name: "Branch", showSort: true },
  { name: "Status", showSort: true },
  // { name: "View Profile", showSort: false },
];

export const data = [
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "in progress",
  },
];

export const assignedHeadings = [
  { name: "Dataform Type", showSort: false },
  { name: "Dataform Frequency", showSort: false },
  // { name: "Action Required", showSort: false },
  // { name: "Status", showSort: false },
  { name: "Sent on", showSort: false },
  { name: "Assigned By", showSort: false },
  { name: "Explore", showSort: false },
];

export const assignedData = [
  {
    _id: "65dc3ba9b0dc39a9437050ab1",
    date: "Feruary '24",
    task_type: "Survey 2 (Month Feb)",
    survey_frequency: "Monthly",
    action_required: "contribution",
    status: "In Progress",
    sent_on: "2024-03-19T11:45:25.426Z",
    hasTitle: true,
    assigned_by: {
      name: "Shanya Natrajan",
      designation: "Flowsource Admin",
      avatar: UserImage,
    },
  },
  // {
  //   _id: "65dc3ba9b0dc39a9437050ab2",
  //   task_type: "SASB Survey (Month Jan)",
  //   survey_frequency: "Every Quarterly",
  //   action_required: "contribution",
  //   hasTitle: true,
  //   status: "In Progress",
  //   sent_on: "14.01.24",
  //   assigned_by: {
  //     name: "Shanya Natrajan",
  //     designation: "Flowsource Admin",
  //     avatar: "",
  //   },
  // },
  {
    _id: "65dc3ba9b0dc39a9437050ab3",
    date: "January '24",
    task_type: "Survey 1 (Quarterly 2)",
    survey_frequency: "Quarterly",
    hasTitle: true,
    action_required: "contribution",
    status: "In Progress",
    sent_on: "2024-03-19T11:45:25.426Z",
    assigned_by: {
      name: "Shanya Natrajan",
      designation: "Flowsource Admin",
      avatar: UserImage,
    },
  },
];

export const completedData = [
  {
    _id: "65dc3ba9b0dc39a9437050ab4",
    task_type: "Survey 2 (Month jan)",
    survey_frequency: "Monthly",
    action_required: "contribution",
    status: "Complete",
    sent_on: "14.12.23",
    assigned_by: {
      name: "Shanya Natrajan",
      designation: "Flowsource Admin",
      avatar: UserImage,
    },
    hasTitle: true,
  },
  // {
  //   _id: "65dc3ba9b0dc39a9437050ab5",
  //   task_type: "SASB Survey (Month Nov)",
  //   survey_frequency: "Every Quarterly",
  //   action_required: "contribution",
  //   status: "Complete",
  //   sent_on: "14.11.23",
  //   assigned_by: {
  //     name: "Shanya Natrajan",
  //     designation: "Flowsource Admin",
  //     avatar: UserImage,
  //   },
  //   hasTitle: true,
  // },
  // {
  //   _id: "65dc3ba9b0dc39a9437050ab6",
  //   task_type: "SASB Survey (Month Oct)",
  //   survey_frequency: "Every Quarterly",
  //   action_required: "contribution",
  //   status: "Complete",
  //   sent_on: "14.10.23",
  //   assigned_by: {
  //     name: "Shanya Natrajan",
  //     designation: "Flowsource Admin",
  //     avatar: "",
  //   },
  //   hasTitle: true,
  // },
  // {
  //   _id: "65dc3ba9b0dc39a9437050ab7",
  //   task_type: "Survey 2 (Quarterly 1)",
  //   survey_frequency: "Every Quarterly",
  //   action_required: "contribution",
  //   status: "Complete",
  //   sent_on: "01.10.23",
  //   assigned_by: {
  //     name: "Shanya Natrajan",
  //     designation: "Flowsource Admin",
  //     avatar: "",
  //   },
  //   hasTitle: false,
  // },
];
