import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function DueDiligenceOverallScore({ severity }) {
  const { surveyScores } = useSelector((state) => state.brmData);

  const nameFormat = (name) => {
    if (name === "overallScore") {
      return "Overall Score";
    }
  };

  const defaultData = {
    overallScore: {
      comment: "Some Comment",
      score: 0,
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      <Grid md={12}>
        <BlueBackgroundCard heading={nameFormat("overallScore")} />
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={"15px"}
          marginLeft={"20px"}
        >
          {surveyScores?.overallScore &&<Chip
            label={
              parseFloat(surveyScores?.overallScore) <= 33
                ? "Low"
                : parseFloat(surveyScores?.overallScore) > 33 &&
                  parseFloat(surveyScores?.overallScore) <= 66
                ? "Mid"
                : "High"
            }
            size="small"
            style={{
              color:
                parseFloat(surveyScores?.overallScore) <= 33
                  ? severity("text", "red")
                  : parseFloat(surveyScores?.overallScore) > 33 &&
                    parseFloat(surveyScores?.overallScore) <= 66
                  ? severity("text", "yellow")
                  : severity("text", "green"),
              backgroundColor:
                parseFloat(surveyScores?.overallScore) <= 33
                  ? severity("bg", "red")
                  : parseFloat(surveyScores?.overallScore) > 33 &&
                    parseFloat(surveyScores?.overallScore) <= 66
                  ? severity("bg", "yellow")
                  : severity("bg", "green"),
            }}
          />}
          <Typography
            style={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "40px" }}>
              {surveyScores?.overallScore || "-"}
            </span>
            <span style={{ fontSize: "20px" }}>/</span>
            <span>100</span>
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            padding: "5px",
            marginBottom: "10px",
          }}
          // component={Paper}
        >
          The score reflects the vendor's performance and can guide them in
          making informed decisions.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DueDiligenceOverallScore;
