import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";

const finalRecommendation = [
  {
    category: "Vendor Intake/Scoping",
    description:
      "While PayWise initially met key criteria for vendor selection, a more thorough risk analysis should be conducted for future engagements to avoid disruptions.",
    recommendation: "Future risk analysis should be more detailed.",
  },
  {
    category: "Risk Assessment",
    description:
      "The identified risks, particularly in the areas of data privacy and regulatory compliance, suggest the need for stronger risk mitigation strategies in future contracts.",
    recommendation:
      "Stronger risk mitigation strategies are required in future contracts.",
  },
  {
    category: "Due Diligence",
    description:
      "While PayWise passed the due diligence phase with a favorable financial standing, improvements in security practices should be mandatory for any future re-engagement.",
    recommendation:
      "Security practices must be improved for future re-engagement.",
  },
  {
    category: "Contracting",
    description:
      "Contract terms were satisfactory, and similar agreements should be established with any replacement vendors.",
    recommendation:
      "Future vendors should have similar agreements to ensure smooth transitions.",
  },
  {
    category: "Onboarding",
    description:
      "PayWise’s onboarding process was effective, and the same approach should be followed with future vendors to ensure minimal service disruption.",
    recommendation:
      "Follow similar onboarding processes for future vendors to avoid disruptions.",
  },
  {
    category: "Monitoring",
    description:
      "Enhanced, more frequent monitoring is recommended for all future vendors to identify and resolve issues earlier in the process.",
    recommendation:
      "Increase the frequency and thoroughness of monitoring for future vendors.",
  },
  {
    category: "Termination and Offboarding",
    description:
      "The termination process was successful, with minimal business impact. A structured offboarding process, including secure data handling and settlement, was efficiently executed.",
    recommendation:
      "Use the same structured offboarding approach for future vendor replacements.",
  },
];

function FinalRecommendation({ classes }) {
  return (
    <Card style={{ marginBottom: "20px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>
          Final Recommendations
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />{" "}
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"space-between"}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            The termination assessment for SwiftPay Solutions revealed the
            following key findings
          </Typography>
          {finalRecommendation?.map((item, i) => (
            <Typography variant="body2" key={i}>
              <strong>{item?.category}: </strong>
              {item?.description}
            </Typography>
          ))}
        </CardContent>
      </Box>
    </Card>
  );
}

export default FinalRecommendation;
