import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Switch,
  Grid,
  Paper,
  IconButton,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Add, Share } from "@material-ui/icons";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import CustomButton from "../../../UI/button/button";
import Introduction from "./Introduction";
import VendorBackground from "./VendorBackground";
import VendorContractDetails from "./VendorContractDetails";
import AssessmentFindings from "./AssessmentFindings";
import FinalRecommendation from "./FinalRecommendation";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#32A05F",
    },
    "&$checked + $track": {
      backgroundColor: "#32A05F",
    },
  },
  checked: {},
  track: {},
  // button: {
  //   borderColor: "#3374B9",
  //   color: "#3374B9",
  //   "&:hover": {
  //     backgroundColor: "#e6f2ff",
  //     borderColor: "#3374B9",
  //   },
  // },
  chip: {
    backgroundColor: "#BFE2CD",
    color: "#195a11",
    // borderColor: "#195a11",
    // border: "1px solid",
    // "& .MuiChip-label": {
    //   color: "#195a11",
    // },
  },
}));

const TprmRiskReport = () => {
  const classes = useStyles();
  return (
    <Box>
      {/* Final Recommendation Header */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>
          Final Recommendation
        </Typography>
        <Box display={"flex"} gridGap={"20px"}>
          <CustomButton
            size="small"
            color="primary"
            startIcon={<OpenInNewRoundedIcon />}
          >
            Export
          </CustomButton>

          <CustomButton size="small" color="primary" startIcon={<Share />}>
            Share
          </CustomButton>

          <CustomButton
            size="small"
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Add Section
          </CustomButton>
        </Box>
      </Box>

      <Box className="scroll">
        {/* Introduction Section */}
        <Introduction classes={classes} />

        {/* Vendor Background Section */}
        <VendorBackground classes={classes} />

        {/* Vendor Contact Details */}
        <VendorContractDetails classes={classes} />

        {/* Assessment Findings */}
        <AssessmentFindings classes={classes} />

        {/* Final Recommendation */}
        <FinalRecommendation classes={classes} />
      </Box>
    </Box>
  );
};

export default TprmRiskReport;
